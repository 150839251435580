import React from 'react';
import Header from '@components/Header';
import Pop from '@components/Pop';
import StyledComponent from './index.style';
import { Boarding, Course } from './Data';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps<any>) => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='双语寄宿小学'
        titleEn='Bilingual Boarding Primary'
        content={
          <>
            <div>我们正在经历一个“童年消逝”的时代</div>
            <div>德普要做的</div>
            <div>是让你在成为井井有条的大人之前</div>
            <div>拥有色彩斑斓的回忆</div>
          </>
        }
      />
      <div className='content '>
        <div className='section children'>
          <div className='title'>德普孩子画像</div>
          <div className='content'>
            <div className='t'>
              如果世上仅剩两碗水，一碗用来喝，一碗要用来洗干净你的脸和衣裤。
            </div>
            <div className='t'>我们希望你，</div>
            <div className='t'>
              可以在春天青翠的草地上疯跑，尽情沐浴阳光，也可以在歌剧院里彬彬有礼，体味艺术的美妙；
            </div>
            <div className='t'>
              可以一口软糯的调子，撒着爸爸妈妈无法拒绝的娇，也可以独立勇敢，即使他们不在身边也能把日子过得井井有条；
            </div>
            <div className='t'>
              爱“自我”，也爱“非我”，跟自己、世界和谐相处；
            </div>
            <div className='t'> 有个性，爱协作，和而不同，懂得爱与分享。</div>
          </div>
        </div>
        <div className='section course'>
          <div className='title'>糖果课程-CANDIE</div>
          <div className='content'>
            <div className='t1'>
              我们要教给孩子的，不是简单的语数高分和永无止境的钢琴、跆拳道考级，而是让孩子明白，学习不止于分数，还有真正属于知识的乐趣；在想象的无限空间里遨游，在玩耍中探寻，让学习变得像呼吸一样自然……
            </div>
            <div className='t2'>
              {Course.map(item => {
                return (
                  <Pop
                    className='item'
                    key={item.right}
                    content={item.content}
                    leftVW={item.leftVW}>
                    <div className='left'>
                      {item.left.split('').map((x, index) => {
                        return <span key={index}>{x}</span>;
                      })}
                    </div>
                    <div className='right'>{item.right}</div>
                  </Pop>
                );
              })}
            </div>
          </div>
        </div>
        <div className='section english'>
          <div className='title'>学科英语</div>
          <div className='content'>
            <div className='t'>
              在德普，英语并不只是英语课的专属。为了给孩子们全面而广泛的语言环境，我们把英文词汇量扩充到了数学、科学领域，英语学习由日常交流转入更为专业的科学、数学领域，引导孩子们换种语言看世界。
            </div>
          </div>
        </div>
        <div className='section sinology'>
          <div className='title'>国学浸润</div>
          <div className='content'>
            <div className='t'>
              我们深知，中国传统文化在滋养孩子心性上的重要性。这绝不仅仅是背诵诗词、强记《三字经》《论语》那么简单。
            </div>
            <div className='t'>
              我们开设了专门的国学课，引入权威出版的国学补充教材，在端午教他们包粽子，在中秋给他们讲嫦娥，让他们认识篆刻、书法、陶艺……凡是可以融入传统文化的地方，我们一个都不愿放过。
            </div>
            <div className='t'>
              我们系统地引入书法练习。从三四年级的楷书、五年级的隶书到六年级的篆书，让孩子们在瓦当魏碑，颜筋柳骨中感受先贤风骨。
            </div>
          </div>
        </div>
        <div className='section boarding'>
          <div className='title'>独立的寄宿制</div>
          <div className='content'>
            <div className='t1'>
              没有人会陪你一辈子，所以你必须从小学会独立。同时，我们还想让你远离“溺爱”、“拖沓”和“不规律”！这些都是成长必须经历的“不情愿”。
            </div>
            <div className='t2'>寄宿还是非寄宿，你生活将有不同：</div>
            <div className='chart'>
              <div className='row'>
                <div className='left'>
                  <div className='block'>寄宿</div>
                </div>
                <div className='middle' />
                <div className='right'>
                  <div className='block not'>非寄宿</div>
                </div>
              </div>
              {Boarding.map(item => {
                return (
                  <div className='row' key={item.time}>
                    <div className='left'>{item.left}</div>
                    <div className='middle'>
                      <div className='circle'>{item.time}</div>
                    </div>
                    <div className='right'>{item.right}</div>
                  </div>
                );
              })}
            </div>
            <div className='t3'>
              寄宿并非关爱的缺失，离开了家人你还有我们。从洗脸刷牙到穿衣系鞋带，从课堂学习到玩耍活动，我们都与
              你在一起！
            </div>
            <div className='list'>
              <div className='item'> ● 生活老师通宵值班；</div>
              <div className='item'>
                ● 洗脸刷牙、穿衣叠被，手把手教你学会照顾自己；
              </div>
              <div className='item'> ● 贴身衣物纯手洗；</div>
              <div className='item'>
                ● 宿舍公区每周消毒一次，寝室每月消毒一次；
              </div>
            </div>
          </div>
        </div>
        <div className='section teacher'>
          <div className='title'>技能满格的师资</div>
          <div className='content'>
            <div className='t'>
              如果你以为，小学老师仅仅是学历高，上课好，那么，我们将给你一个颠覆。
            </div>
            <div className='t'> 在德普，小学部的老师是这样的，他们要：</div>
            <div className='t'>
              画得了画，卖得了萌，变得了魔术，跳得了舞，编得了剧，凝聚得了团队，搞得定学生……
            </div>
            <div className='t'>还要写得了文案，剪得了视频……</div>
            <div className='t'>
              当然，最重要的是，带领孩子们一起，自信而美丽！
            </div>
            <div className='t'>
              想了解这些全能而多才的老师?
              <span
                className='link'
                onClick={() => {
                  props.history.push('/elitefaculties?t=1');
                  window.scrollTo(0, 0);
                  localStorage.setItem('fromIndex', 'true');
                }}>
                看这里……
              </span>
            </div>
          </div>
        </div>
        {/* <div className='section authentication'>
          <div className='title'>国际认证</div>
          <div className='content'>
            <div className='t1'>
              ——德普是剑桥大学国际考评部（Cambridge
              International）官方认证的“重庆剑桥国际中心”，也是西南地区首家被Cambridge
              International授权的剑桥教师职业发展中心（PDQ），承担着为中国西南地区剑桥课程教师提供教学教研培训的重任。德普还是：
            </div>
            <div className='t2'>
              <div>美国大学理事会（The College Board）授权学校</div>
              <div>
                英国爱德思学术资质及考试中心(Edexcel Academic Qualifications)
              </div>
              <div>
                英国教育文化协会认证普思考试示范学校（APTIS Model School）
              </div>
              <div>2019中国新锐国际学校</div>
              <div>2019剑桥国际东亚区大会承办学校</div>
              <div>重庆市外商投资企业协会战略合作伙伴</div>
              <div>新加坡重庆商会战略合作伙伴</div>
            </div>
          </div>
        </div> */}
      </div>
    </StyledComponent>
  );
};
