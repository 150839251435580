import debug from './debug';
import initWx from './wx';
import storage from '@utils/storage';
import isWx from '@utils/isWx';

const isProd = process.env.NODE_ENV === 'production' && isWx;

const initWxFn = () =>
  initWx({
    isNeedLogin: false,
    title: '重庆德普外国语学校',
    imgUrl: `${window.location.origin}${require('../images/icon.jpg')}`,
    desc: '3.0时代的国际化学校，2019中国新锐国际学校，培养懂中国懂世界的创变者'
  });

export default async () => {
  storage.set('appid', 'wx179573d3bf2fedb7');
  await debug().then(async () => {
    if (isProd) {
      await initWxFn();
    } else {
      storage.set('openid', 'oAl2A0pBmpYrqL6oYdbz8YJGlRbU');
      storage.set('third_token', 'Z5RZB5tIhO337pqP8AHO');
    }
  });
};
