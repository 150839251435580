import React, { useState } from 'react';
import styled from 'styled-components';
import LazyImg from '@components/LazyImg';
import { Picker } from 'antd-mobile';
import { RouteComponentProps } from 'react-router-dom';
// import EM from '@utils/EM';
import previewImgs from '@utils/previewImgs';

export default styled((props: RouteComponentProps<any> & { id: string }) => {
  const { staticContext, ...rest } = props;
  const [calendarPickerVisible, setCalendarPickerVisible] = useState(false);
  const tags = [
    {
      title: '咨询须知',
      // color: '#B20000',
      color: '#666666',
      img: require('./咨询须知.png'),
      href:
        'https://mp.weixin.qq.com/s?__biz=MzI5NDA2Mzg1Mg==&mid=2650191160&idx=1&sn=79eeb23b4920e18ccb0ec3f90e9d911a&chksm=f46a8ebbc31d07ad660561e7ecec9745c458f3d828aa75c94816608963b779257f0bb4cf81fb#rd'
    },
    {
      title: '师资',
      // color: '#009ED0',
      color: '#666666',
      img: require('./师资.png'),
      // href: 'http://m.idepu.org/elitefaculties/'
      to: '/elitefaculties'
    },
    {
      title: '预约参观',
      // color: '#97D700',
      color: '#666666',
      img: require('./预约参观.png'),
      // onClick: () => {
      //   EM.emit('ShowAppointment');
      // }
      href: 'https://www.aliwork.com/o/zixunlist1'
    },
    {
      title: 'English',
      // color: '#FFB909',
      color: '#666666',
      img: require('./德普校历.png'),
      // onClick: () => setCalendarPickerVisible(true)
      // href: 'https://m.idepu.org'
      to: '/english'
    },
    {
      title: '双语寄宿小学',
      // color: '#FFB909',
      color: '#666666',
      img: require('./寄宿小学.png'),
      to: '/bilingual-boarding-primary'
    },
    {
      title: '小班初中',
      // color: '#FFB909',
      color: '#666666',
      img: require('./小班初中.png'),
      to: '/smartjunior'
    },
    {
      title: '双语初中',
      // color: '#FFB909',
      color: '#666666',
      img: require('./双语初中.png'),
      to: '/bilingualjunior'
    },
    {
      title: '国际高中',
      // color: '#FFB909',
      color: '#666666',
      img: require('./国际高中.png'),
      to: '/internationalsenior'
    }
  ];
  const calendarData = [
    {
      label: '双语寄宿小学',
      value: require('../calendarImgs/双语寄宿小学.jpg')
    },
    {
      label: '小班初中',
      value: require('../calendarImgs/小班初中.jpg')
    },
    {
      label: '双语初中',
      value: require('../calendarImgs/双语初中.jpg')
    },
    {
      label: '国际高中',
      value: require('../calendarImgs/国际高中.jpg')
    }
  ];
  return (
    <>
      <div {...rest} id={rest.id}>
        {tags.map(tag => {
          return (
            <div
              className='tag'
              key={tag.img}
              onClick={() => {
                if (tag.href) {
                  window.location.href = tag.href;
                }
                // if (tag.onClick) {
                //   tag.onClick();
                // }
                if (tag.to) {
                  props.history.push(tag.to);
                  window.scrollTo(0, 0);
                  localStorage.setItem('fromIndex', 'true');
                }
              }}>
              <LazyImg src={tag.img} alt='tag' />
              <div className='text' style={{ color: tag.color }}>
                {tag.title}
              </div>
            </div>
          );
        })}
      </div>
      <Picker
        cols={1}
        visible={calendarPickerVisible}
        data={calendarData}
        onDismiss={() => {
          setCalendarPickerVisible(false);
        }}
        onOk={e => {
          previewImgs({
            url: e[0]
          });
          setCalendarPickerVisible(false);
        }}
      />
    </>
  );
})`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 20px 42px 20px;
  > .tag {
    margin-right: 30px;
    padding-top: 42px;
    width: 150px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    > img {
      height: 109px;
      margin: auto;
    }
    > .text {
      padding-top: 5px;

      width: 100%;
      text-align: center;

      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
`;
