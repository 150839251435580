import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import StyleComponent, { WrapStyleComponent } from './index.style';
export default (
  props: React.HTMLAttributes<HTMLDivElement> & {
    content?: React.ReactNode;
    leftVW?: string;
  }
) => {
  const [isShow, setIsShow] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isShow || !props.content || !domRef.current) {
      return;
    }
    const rect = domRef.current.getBoundingClientRect();
    const node = document.createElement('div');
    const overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    document.body.append(node);
    const handle = (e: TouchEvent) => {
      e.stopPropagation();
      e.preventDefault();
    };
    document.addEventListener('touchmove', handle, { passive: false });
    ReactDOM.render(
      <StyleComponent
        onTouchStart={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsShow(false);
        }}>
        <div
          className='wrap'
          style={{
            top: rect.top
          }}>
          <div className='arrow' />
          <div className='content'>{props.content}</div>
        </div>
      </StyleComponent>,
      node
    );
    return () => {
      document.body.style.overflow = overflow;
      ReactDOM.unmountComponentAtNode(node);
      document.body.removeChild(node);
      document.removeEventListener('touchmove', handle);
    };
  }, [isShow, props.content, domRef]);
  return (
    <WrapStyleComponent
      ref={domRef}
      {...Object.assign({}, props, {
        content: undefined,
        children: undefined,
        className: props.content ? props.className + ' active' : props.className
      })}
      onClick={e => {
        setIsShow(true);
        props.onClick && props.onClick(e);
      }}>
      {props.content && (
        <img
          src={require('./icon.svg')}
          alt='icon'
          className='icon'
          style={{
            left: props.leftVW
          }}
        />
      )}
      {props.children}
    </WrapStyleComponent>
  );
};
