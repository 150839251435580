import styled from 'styled-components';
export default styled.div`
  width: 720px;
  //overflow: hidden;
  margin: auto;
  box-sizing: border-box;
  > .title {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 10px 50px 10px;
    > .line {
      height: 2px;
      width: 210px;
      background-color: rgba(112, 112, 112, 1);
    }
  }
  > .swiper-container {
    width: 720px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 20px;
    > .swiper-wrapper {
      > .swiper-slide {
        padding: 30px 23px 0 23px;
        box-sizing: border-box;
        > .photo {
          height: 360px;
          width: 360px;
          border-radius: 100%;
          background-color: #ccc;
          margin: 50px auto 0 auto;
          overflow: hidden;
          position: relative;
          > img {
            width: 100%;
          }
        }
        > .name {
          text-align: center;
          > .text {
            height: 60px;
            background: rgba(0, 72, 68, 1);
            border-radius: 120px;
            margin: 50px auto 0 auto;

            font-size: 36px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);

            display: inline-flex;
            justify-content: center;
            align-items: center;

            padding: 0 80px;
          }
        }
        > .desc {
          font-size: 28px;
          font-weight: 400;
          line-height: 44px;
          color: rgba(0, 0, 0, 1);
          padding: 30px 10px 0 10px;
        }
        > .idea {
          padding: 30px 10px 10px 10px;
          font-size: 28px;
          font-weight: 400;
          line-height: 44px;
          color: rgba(102, 102, 102, 1);
        }
        > .descEn {
          padding: 30px 10px 10px 10px;
          font-size: 26px;
          font-weight: 400;
          line-height: 44px;
          color: rgba(102, 102, 102, 1);
        }
        > .gap {
          width: 241px;
          height: 4px;
          background: rgba(73, 111, 87, 1);
          margin: 60px auto 0 auto;
        }
      }
    }
    > .page {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      > .btn {
        width: 42px;
        > img {
          width: 100%;
        }
      }
      > .progress {
        padding: 0 20px;
      }
    }
  }
`;
