import React, { useEffect, useRef } from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';
import { countrySchool } from '@pages/InternationalSenior/Data';
// import { images2, links } from './Data';
// import LazyImg from '@components/LazyImg';
import { animateScroll } from '@src/App';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps<any>) => {
  // const [isShowMore, setIsShowMore] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!domRef.current) {
      return;
    }
    const match = props.location.search.match(/t=(\d)/);
    if (match && match[1]) {
      const index = parseInt(match[1]);
      const els = domRef.current.querySelectorAll('[data-index]');
      if (els) {
        const el = els[index];
        setTimeout(() => {
          animateScroll(el as HTMLElement, 30, 'auto');
        }, 500);
      }
    }
  }, [props.location.search]);
  return (
    <StyledComponent ref={domRef}>
      <Header
        img={require('./header.png')}
        title='德普毕业生'
        titleEn='DEPU Graduates'
        content={
          <>
            <div>我们相信，</div>
            <div>每一份努力都会有所收获，</div>
            <div>每一个付出都会有所回报。</div>
            <div>那些洒下的汗水熬过的夜，终将回报你的人生。</div>
          </>
        }
      />
      <div className='content '>
        <div className='section graduate'>
          <div className='title' />
          <div className='content'>
            <div className='t'>
              每一年，德普毕业生都从这里出发，走向世界各地。
            </div>
            <div className='t'>
              英国、美国、澳大利亚、加拿大、新加坡、中国香港......
              在这些国家和地区的顶尖高校里，有了越来越多德普学子的身影。
            </div>
            <div className='t'>
              我们欣喜于孩子们取得的成绩，他们用自己的付出，让自己的世界更加辽阔。
            </div>
            <div className='title'>德普历届毕业生录取国家及学校</div>
            <div className='table1'>
              {countrySchool.map(x => {
                return (
                  <div className='row' key={x.country}>
                    <div className='col'>{x.country}</div>
                    <div className='col'>
                      {(x.school || []).map((x, i) => (
                        <div key={i}>{x}</div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='t'>
              由于篇幅有限，我们仅摘录了部分毕业生的录取信息，但不管是Top10，还是Top100，每一个为梦想努力奋斗的他们，都是我们的骄傲。
            </div>
            <div className='t'>
              从专业走向上看，
              孩子们的选择也让我们欣慰。他们或出于热爱，或出于理想主义，无论是热门的STEM专业还是人文艺术专业，都是孩子们遵从自己内心的选择。
            </div>
            <div className='t'>我们乐于见到学生多样性的成长</div>
          </div>
        </div>
      </div>
      <div className='block'>
        <img src={require('./专业@2x.png')} alt='专业' />
      </div>
      <div className='content'>
        <div className='section graduate'>
          <div className='content'>
            <div className='t'>我们相信，</div>
            <div className='t'>
              每一份努力终会有所收获，每一个付出终会有所回报。
            </div>
            <div className='t'>那些洒下的汗水熬过的夜，终将不负你的人生。</div>
          </div>
        </div>
      </div>
      {/* <div className='blocks' data-index={0}>
        {(isShowMore ? images2 : images2.slice(0, 5)).map((item, index) => {
          return (
            <div
              className='block'
              key={index}
              onClick={() => {
                if (!links[index]) {
                  return;
                }
                window.location.href = links[index];
              }}>
              <LazyImg src={item} alt='' />
            </div>
          );
        })}
      </div> */}
      {/* {!isShowMore ? (
        <div
          className='more'
          onClick={() => {
            setIsShowMore(true);
          }}>
          <div className='text'>点击查看更多毕业生</div>
          <img src={require('./more.svg')} alt='' />
        </div>
      ) : (
        undefined
      )} */}
    </StyledComponent>
  );
};
