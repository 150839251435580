export default [];
export const countrySchool = [
  {
    country: '国家',
    school: ['录取学校']
  },
  {
    country: '英国',
    school: [
      '牛津大学',
      'University of Oxford',
      '剑桥大学',
      'University of Cambridge',
      '帝国理工学院',
      'Imperial College London',
      '伦敦政治经济学院',
      'London School of Economics and Political Science',
      '伦敦大学学院',
      'University College London',
      '爱丁堡大学',
      'University of Edinburgh',
      '曼彻斯特大学',
      'University of Manchester',
      '伦敦国王学院',
      "King's College London",
      '布里斯托大学',
      'University of Bristol',
      '华威大学',
      'The University of Warwick',
      '格拉斯哥大学',
      'University of Glasgow',
      '杜伦大学',
      'Durham University',
      '谢菲尔德大学',
      'The University of Sheffield',
      '伦敦艺术大学',
      'University of the Arts London'
    ]
  },
  {
    country: '美国',
    school: [
      '芝加哥大学',
      'University of Chicago',
      '哥伦比亚大学',
      'Columbia University',
      '加州大学伯克利分校',
      'University of California, Berkeley',
      '加州大学洛杉矶分校',
      'University of California, Los Angeles',
      '纽约大学',
      'New York University',
      '东北大学',
      'Northeastern University',
      '波士顿大学',
      'Boston University',
      '北卡罗莱纳大学教堂山分校',
      'The University of North Carolina at Chapel Hill',
      '普瑞特艺术学院',
      'Pratt Institute',
      '萨凡纳艺术与设计学院',
      'Savannah College of Art and Design',
      '艺术中心设计学院',
      'Art Center College of Design'
    ]
  },
  {
    country: '加拿大',
    school: [
      '多伦多大学',
      'University of Toronto',
      '麦吉尔大学',
      'McGill University',
      '英属哥伦比亚大学',
      'University of British Columbia',
      '滑铁卢大学',
      'University of Waterloo'
    ]
  },
  {
    country: '日本',
    school: [
      '日本早稻田大学',
      'Waseda University',
      '法政大学',
      'Hosei University',
      '立命馆亚洲太平洋大学',
      'Ritsumeikan Asia Pacific University'
    ]
  },
  {
    country: '澳大利亚',
    school: [
      '澳洲国立大学',
      'Australian National University',
      '墨尔本大学',
      'The University of Melbourne',
      '悉尼大学',
      'The University of Sydney',
      '新南威尔士大学',
      'The University of New South Wales'
    ]
  },
  {
    country: '中国·香港',
    school: [
      '香港大学',
      'The University of Hong Kong',
      '香港城市大学',
      'City University of Hong Kong',
      '香港中文大学',
      'The Chinese University of Hong Kong',
      '香港科技大学',
      'The Hong Kong University of Science and Technology',
      '香港理工大学',
      'The Hong Kong Polytechnic University'
    ]
  },
  {
    country: '中国·澳门',
    school: [
      '澳门大学',
      'University of Macau',
      '澳门科技大学',
      'Macau University of Science and Technology'
    ]
  },
  {
    country: '新加坡',
    school: [
      '新加坡南洋理工大学',
      'Nanyang Technological University',
      '新加坡国立大学',
      'National University of Singapore'
    ]
  },
  {
    country: '荷兰',
    school: [
      '伊拉斯姆斯大学',
      'Erasmus University Rotterdam',
      '阿姆斯特丹大学',
      'Universiteit van Amsterdam',
      '莱顿大学',
      'Leiden University'
    ]
  },
  {
    country: '新西兰',
    school: ['新西兰奥克兰大学', 'The University of Auckland']
  },
  {
    country: '马来西亚',
    school: ['马来亚大学', 'University of Malaya']
  },
  {
    country: '爱尔兰',
    school: ['都柏林圣三一大学', 'Trinity College Dublin']
  },
  {
    country: '瑞士',
    school: [
      '瑞士格里昂酒店管理学院',
      'Glion Institute of Higher Education',
      '理诺士国际酒店管理学院',
      'Les Roches International School of Hotel Management'
    ]
  },
  {
    country: '中国',
    school: ['西交利物浦大学', "Xi'an Jiaotong-liverpool University"]
  }
];
export const rank = [
  { country: '国家', school: '学校' },
  { country: '英国', school: '牛津大学' },
  { country: '英国', school: '剑桥大学' },
  { country: '英国', school: '帝国理工学院' },
  { country: '英国', school: '伦敦大学学院' },
  { country: '新加坡', school: '新加坡南洋理工大学' },
  { country: '美国', school: '哥伦比亚大学' },
  { country: '英国', school: '爱丁堡大学' },
  { country: '中国香港', school: '香港大学' },
  { country: '加拿大', school: '多伦多大学' },
  { country: '英国', school: '曼彻斯特大学' },
  { country: '中国香港', school: '香港科技大学' },
  { country: '澳大利亚', school: '澳洲国立大学' },
  { country: '英国', school: '伦敦国王学院' },
  { country: '加拿大', school: '麦吉尔大学' },
  { country: '美国', school: '纽约大学' },
  { country: '美国', school: '加州大学洛杉矶分校' },
  { country: '澳大利亚', school: '悉尼大学' },
  { country: '澳大利亚', school: '墨尔本大学' },
  { country: '中国香港', school: '香港中文大学' },
  { country: '澳大利亚', school: '新南威尔士大学' },
  { country: '加拿大', school: '英属哥伦比亚大学' },
  { country: '澳大利亚', school: '昆士兰大学' },
  { country: '中国香港', school: '香港城市大学' },
  { country: '英国', school: '伦敦政治经济学院' }
];
export const Course = [
  {
    left: '中国高中课程',
    right: 'Chinese Senior Curriculum'
  },
  {
    left: '定向国际课程',
    right: 'Oriented International Curriculum'
  },
  {
    left: '语言课程',
    right: 'Multiple Language Learning'
  },
  {
    left: '品格塑造课程',
    right: 'Practice of Character Training',
    content:
      '击剑、马术、壁球、椒榄球、跆拳道、游泳、攀岩、高尔夫、味春拳、川剧、街舞、艺术与设计、电影工作坊、非洲数、导演课、漫研社、橄概球、计算机、辩论杜、杂志杜、Funy Engish Cub、模抑联合国、戏剧壮、瑜伽杜、科学杜、领导社、神秘动物学杜、类语戏剧社',
    leftVW: '85vw'
  },
  {
    left: '高阶生涯教育',
    right: 'Advanced Life Education'
  },
  {
    left: '中外文史哲探究',
    right: 'Sino-Western Culture & Philosophy'
  }
];
