import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .block {
    width: 716px;
    height: 146px;
    background: rgba(159, 184, 167, 1);
    box-shadow: 12px 12px 0 0 rgba(0, 72, 68, 1);

    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
    padding: 25px 35px;
    box-sizing: border-box;
  }
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 70px;
        color: #004844;
        padding-top: 60px;
      }
      &.graduate {
        > .content {
          > .subTitle {
            padding-top: 60px;
            font-size: 30px;
            font-weight: bold;
            line-height: 50px;
            color: rgba(0, 0, 0, 1);
          }
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding-top: 40px;
          }
          > .tt1 {
            padding-top: 40px;
            font-size: 44px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
          > .tt2 {
            padding-top: 10px;
            font-size: 32px;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
`;
