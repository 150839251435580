import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
      }
      &.graduate {
        > .content {
          > .t1 {
            padding-top: 40px;
            font-weight: 500;
            font-size: 28px;
            line-height: 50px;
            color: #333;
          }
          > .t2 {
            padding-top: 20px;
            font-weight: 300;
            font-size: 28px;
            line-height: 58px;
            color: #666;
          }
          > .t3 {
            padding-top: 20px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            color: #666;
          }
        }
      }
      &.course {
        > .content {
          > .t1 {
            padding-top: 40px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            text-align: left;
            color: #666;
          }
          > .t2 {
            padding-top: 20px;
            font-weight: 500;
            font-size: 30px;
            line-height: 50px;
            color: #333;
          }
          > .t3 {
            padding-top: 20px;
            > .item {
              font-weight: 300;
              font-size: 28px;
              line-height: 50px;
              color: #666;
              display: flex;
              justify-content: center;
              align-items: center;
              > .left {
                width: 210px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              > .right {
                padding-left: 20px;
                flex: 1;
                position: relative;
                &:before {
                  content: ' ';
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                  width: 1px;
                  height: 60%;
                  background-color: #666;
                }
              }
            }
          }
        }
      }
      &.feature {
        > .content {
          > .item {
            > .title {
              padding-top: 30px;
              font-weight: normal;
              font-size: 30px;
              line-height: 50px;
              color: #000;
            }
            > .content {
              padding-top: 30px;
              font-weight: 300;
              font-size: 28px;
              line-height: 50px;
              color: #666;
              > .gap {
                height: 30px;
              }
              > .img {
                width: 100%;
                display: block;
              }
            }
          }
        }
      }
      &.outside {
        > .content {
          > .t1,
          > .t2 {
            padding-top: 20px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            color: #666;
          }
          > .t1 {
            padding-top: 40px;
          }
          > .block {
            padding-top: 20px;
            > .title {
              font-weight: 300;
              font-size: 28px;
              line-height: 57px;
              color: #333;
            }
            > .content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              padding-left: 35px;
              > .item {
                width: 50%;
                font-weight: 300;
                font-size: 26px;
                line-height: 50px;
                color: #666;
              }
            }
          }
        }
      }
      &.teacher {
        > .content {
          > .t {
            padding-top: 20px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            color: #666;
          }
          > .t1 {
            padding-top: 40px;
          }
          > .link {
            padding-top: 20px;
            font-weight: normal;
            font-size: 28px;
            line-height: 40px;
            color: #177de0;
          }
        }
      }
    }
  }
`;
