export default `
01王波 Charlie
⼩学部高学段副校⻓
原精一民族小学副校长
重庆市教委国培专家库成员
重庆市骨干教师
重庆市小学数学现场赛课一等奖
全国小学数学录像课比赛一等奖
descEn
Vice principal of Bilingual Boarding Primary Department
Vice Principal of Chongqing Jingyi Ethnic Primary School
Member of  National Training Expert Database of Chongqing Education Commission 
Backbone Teacher of Chongqing
First Prize of Chongqing Primary School Mathematics class Competition  
First prize of National Primary School Mathematics Video Course Competition
--
02蒋丽萍 Rose
⼩学部低学段副校⻓
重庆市语文骨干教师
重庆市语文赛课一等奖
渝中区优秀德育工作者
渝中区优秀教师
全国论文比赛一等奖
重庆市语文赛课一等奖指导教师
descEn
Vice principal of Bilingual Boarding Primary Department
Excellent Moralist of Yuzhong District
Excellent Teacher of Yuzhong District
First Prize of Chongqing Chinese Language Competition
Chongqing Backbone Chinese Teacher
First Prize of the National Paper Competition
Published 7 papers and educational narratives
Tutor of the first prize of Chongqing Chinese Language Competition
--
03王丹 Ivy
小学部教学中心GC主任
硕士毕业于剑桥大学
重庆市英语优质课一等奖（录像课）
全国小学英语现场说课一等奖第一名
descEn
Dean of Teaching Centre of Bilingual Boarding Primary Department
Master's degree from Cambridge University
First Prize of Chongqing English Quality Course (Video Course)
Won the first prize of National Primary School English Speaking Class
--
04邓雪 Berry
小学部德育主任
荣获重庆市体育教师基本功比赛一等奖
荣获重庆市中小学体育优质课比赛一等奖
荣获一师一优课比赛部级优课
2022年被评选为重庆市巴南区体育资源库专家
descEn
Dean of Morality Centre of Bilingual Boarding Primary Department
First Prize of Chongqing Physical Education Teacher Basic Skills Competition;
First Prize of Chongqing Primary and Secondary School Sports Excellent Quality Lesson Competition;
Won the national Excellent Course in the first-level Excellent Course Competition;
Selected as Sports Resource expert of Banan District, Chongqing in 2022;
--
06周庆 Zoe
小学部语文老师
重庆市第十届班主任基本功比赛全能特等奖
重庆市法治优质课竞赛二等奖
重庆市基础教育论文二等奖
descEn
Chinese Teacher
Speical Omnipotent Award of Chongqing 10th Homeroom Teacher Basic Skills Competition 
Second Prize of Chongqing Law Course Competition;
Second Prize of Chongqing Basic Education Paper;
--
08傅豪 Rich
小学部数学老师
教育部“一师一优课，一课一名师”部优奖
2020年市级小学数学论文比赛二等奖
descEn
Math Teacher
Won the national Excellent Course in the first-level Excellent Course Competition;
Second Prize in 2020 Municipal Primary school Mathematics Paper Competition
--
09胡瑾 Jane
小学部数学教师
新加坡国立大学
descEn
Math Teacher
National University of Singapore
--
10陈果 coco
小学部数学教师
重庆市2019年“国培计划”市级骨干教师培训计划优秀学员
曾2次代表重庆市到全国上观摩课
全国九省市第五届小学数学优质课比赛一等奖
第四届中小学教师微课大赛一等奖
重庆市首届新课程小学优秀课例评比一等奖
descEn
Math Teacher
Chongqing 2019 "National Training Plan" Chongqing Backbone Teacher Training Plan Primary School Mathematics Outstanding Member
First prize of the 5th National Primary School Mathematics Excellent Quality Lesson Competition of nine provinces and cities
First prize of the 4th Primary and Secondary School Teacher Micro-Lesson Competition
First prize of Chongqing's first New Curriculum Excellent primary school class Competition
Twice participated in watching classes all over the country as  behalf of Chongqing Municipality
--
13姜文 Steven
小学部美术教师
四川美术学院雕塑系硕士
获2022年重庆市中小学音乐美术教师基本功大赛一等奖
descEn
Art teacher
Master's Degree in Sculpture Department of Sichuan Fine Arts Institute
The municipal first prize of Chongqing Elementary and Middle School Music and Art Teachers Basic Skills Exhibition in 2022
`;
