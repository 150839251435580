import React, { useEffect, useRef, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import styled from 'styled-components';
import Loading from '@components/loading';
const panzoom = require('pan-zoom');

export default ({ url }: { url: string }) => {
  const dom = document.createElement('div');
  const Component = () => {
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(true);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      if (modalVisible) {
        document.body.appendChild(dom);
        setTimeout(() => {
          document.body.style.overflow = 'hidden';
        }, 300);
      } else {
        document.body.style.overflow = 'auto';
        setTimeout(() => {
          document.body.removeChild(dom);
          unmountComponentAtNode(dom);
        }, 300);
      }
    }, [modalVisible]);

    useEffect(() => {
      if (!url) {
        return;
      }

      const asyncFunc = async () => {
        const elCanvas = canvasRef.current;
        if (!elCanvas) {
          return;
        }
        const rect = elCanvas.getBoundingClientRect();
        const HEIGHT = rect.height * 2;
        const WIDTH = rect.width * 2;

        elCanvas.setAttribute('height', HEIGHT + 'px');
        elCanvas.setAttribute('width', WIDTH + 'px');
        const ctx = elCanvas.getContext('2d');
        if (!ctx) {
          return;
        }
        const img: HTMLImageElement = await new Promise(resolve => {
          const el = new Image();
          el.onload = () => {
            resolve(el);
          };
          el.setAttribute('src', url);
        });

        let x = 0,
          y = 0,
          scale = 0.3;

        const draw = () => {
          const width = img.width * scale;
          const height = img.height * scale;
          ctx.clearRect(0, 0, WIDTH, HEIGHT);
          ctx.drawImage(img, 0, 0, img.width, img.height, x, y, width, height);
          requestAnimationFrame(draw);
        };
        draw();

        panzoom(elCanvas, (e: any) => {
          // FIXME 缩放中心点
          let ds = -e.dz / 1000;
          scale += ds;
          if (scale <= 0) {
            scale = 0.1;
            ds = 0;
          }

          // console.log('ds', ds);
          // console.table(e, ['dx', 'dy', 'dz', 'x', 'y']);
          x += e.dx * 2;
          x -= (WIDTH * ds) / 2;
          x -= (e.x * ds) / 2;

          y += e.dy * 2;
          y -= (HEIGHT * ds) / 2;
          y -= (e.y * ds) / 2;

          console.log(x, y, scale);
        });
      };
      asyncFunc().then(() => {
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);
    return modalVisible ? (
      <Wrap>
        <img
          src={require('../pages/Index/close.svg')}
          alt='close'
          className='close'
          onClick={() => {
            setModalVisible(false);
            document
              .querySelector('html')
              .setAttribute('style', 'pisition: relative');
          }}
        />
        <canvas ref={canvasRef} />
        {loading && <Loading />}
      </Wrap>
    ) : null;
  };
  render(<Component />, dom);
};

const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  user-select: none;
  > .close {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 80px;
    z-index: 1;
  }
  > canvas {
    height: 100%;
    width: 100%;
  }
  img {
    /* width: 1200px; */
    /* width: 100%; */
  }
`;
