import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

const items = [
  { text1: '1:1', text2: '一对一家长会', text3: '', color: '#D89D07' },
  { text1: '1:4', text2: '师生比例', text3: '', color: '#B30010' },
  {
    text1: '15',
    text2: '德普师生来自15个国家和地区',
    text3: '国际学生近100人',
    color: '#0085B5'
  },
  { text1: '80+', text2: '体艺拓展课程', text3: '', color: '#0DB900' },
  { text1: '50+', text2: '学生每日阅读分钟数', text3: '', color: '#271818' },
  { text1: '1.5', text2: '每日体艺活动小时数', text3: '', color: '#64548E' },
  { text1: '30门', text2: 'IG+A-Level课程', text3: '', color: '#8E396D' },
  { text1: '8个', text2: '国际权威教育认证', text3: '', color: '#065700' },
  { text1: '14个', text2: '国际学术竞赛赛点', text3: '', color: '#271818' },
  { text1: '42个', text2: '学生社团', text3: '', color: '#846FB9' }
];

interface Props {
  className?: string;
  id?: string;
}

export default styled((props: Props) => {
  const domRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const mySwiper = new Swiper(domRef.current!, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      autoplay: true,
      pagination: { el: paginationRef.current! }
      // initialSlide: 2
    });
    return () => {
      mySwiper.destroy(true, true);
    };
  }, []);
  return (
    <div
      className={props.className + ' swiper-container'}
      id={props.id}
      ref={domRef}>
      <div className='swiper-wrapper'>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className='swiper-slide'
              style={{
                backgroundColor: item.color
              }}>
              <div className='wrap'>
                <div className='text1'>{item.text1}</div>
                <div className='text2'>{item.text2}</div>
                <div className='text3'>{item.text3}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='swiper-pagination' ref={paginationRef} />
    </div>
  );
})`
  width: 100%;
  height: 400px;
  padding-top: 10px;
  padding-bottom: 31px;
  .swiper-slide {
    background: red;
    width: 670px;
    margin: 0 9.5px;
    border-radius: 12px;
    position: relative;
    > .wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 0 64px;
      width: 100%;
      > .text1 {
        font-weight: bold;
        font-size: 76px;
        letter-spacing: 0.2em;
        //line-height: 42px;
        text-align: center;
        color: #fff;
        //border: 3px solid #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.31);
        padding-bottom: 16px;
      }
      > .text2,
      > .text3 {
        font-weight: normal;
        font-size: 37px;
        letter-spacing: 0.12em;
        //line-height: 41px;
        text-align: center;
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.42);
      }
    }
  }
  .swiper-pagination {
    bottom: 5vw;
    .swiper-pagination-bullet {
      height: 1.5vw;
      width: 1.5vw;
      margin: 0 1vw;
      opacity: 0.5;
      &.swiper-pagination-bullet-active {
        background-color: #08a29a;
        opacity: 1;
      }
    }
  }
`;
