import styled from 'styled-components';
export default styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  > .wrap {
    padding: 50px 65px;
    > .bonus {
      > .title {
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        line-height: 46px;
        color: rgba(0, 72, 68, 1);
      }
      > .title2 {
        padding-top: 20px;
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        line-height: 46px;
        color: rgba(0, 72, 68, 1);
      }
      > .content {
        padding-top: 30px;
        > .table {
          background-color: #fff;
          border: 2px solid #004844;
          > .block {
            text-align: center;
            > .top {
              padding: 20px 0;
              font-weight: 400;
              line-height: 40px;
              color: rgba(0, 72, 68, 1);
              > .t1 {
                font-size: 40px;
              }
              > .t2 {
                font-size: 30px;
              }
            }
            > .bottom {
              padding: 20px 0;
              background-color: #004844;
              &:last-child {
                border-top: 1px dashed rgba(255, 255, 255, 0.4);
              }
              > .t {
                font-size: 30px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 60px;
              }
            }
          }
        }
      }
    }
  }
`;
