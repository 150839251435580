import styled from 'styled-components';
export default styled.div`
  --red: #ba0001;
  --blue: #004844;
  font-size: 28px;
  font-weight: 300;
  line-height: 50px;
  color: rgba(102, 102, 102, 1);
  padding: 0 34px;
  padding-bottom: 100px;
  .title {
    font-size: 44px;
    font-weight: 550;
    line-height: 44px;
    color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0px 50px 0px;
    > .line {
      height: 2px;
      width: 210px;
      background-color: rgba(112, 112, 112, 1);
    }
  }

  .sub-title {
    color: var(--red);
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .content {
    margin-bottom: 60px;
  }

  em {
    color: var(--blue);
    font-style: normal;
  }

  > .introduction {
    ul {
      list-style: none;
      padding-left: 30px;
      > li {
        display: flex;
        justy-content: flex-start;
        &.gap {
          margin-bottom: 7px;
        }

        .name {
          word-break: keep-all;
          text-align: justify;
          text-align-last: justify;
          width: 135px;
          font-weight: 550;

          &.gaoji {
            font-size: 26px;
          }
        }
        .name ::after {
          content: '：';
          font-weight: 550;
        }
        .descript {
          // width: calc(100vw - 64px - 135px);
          // background: red;
          text-align: left;
          display: block;
        }
      }
    }
  }

  .conditions {
    ul {
      list-style: none;
      padding-left: 30px;

      > li {
        display: flex;
        justy-content: flex-start;

        .name {
          word-break: keep-all;
          text-align: justify;
          text-align-last: justify;
          width: 135px;
          font-weight: 500;
        }
        .name ::after {
          content: '：';
          font-weight: 550;
        }

        .and-so-on {
          font-weight: 550;
          width: 100px;
          display: block;
          text-align: justify;
          text-align-last: justify;
        }
      }
    }

    .content {
      margin-bottom: 5px;
      .summary {
        font-weight: 550;

        &.colon::after {
          content: '：';
          font-weight: 550;
        }
      }
    }
  }

  .culture {
    .section {
      color: var(--red);
      font-size: 30px;
      margin-bottom: 25px;
      font-weight: bold;
    }

    .sub-title {
      color: rgb(0, 0, 0);
      margin-bottom: 10px;
    }
  }

  > .leaders {
    padding-top: 30px;
    padding-bottom: 55px;
    > .each {
      font-size: 36px;
      margin-bottom: 40px;
    }
    > .blank {
      height: 40px;
    }
  }

  > .teachers {
    ul {
      list-style: none;
      padding-left: 0;
      > li {
        display: flex;
        justy-content: flex-start;
        .name {
          word-break: keep-all;
          text-align: justify;
          text-align-last: justify;
          width: 135px;
          &.gaoji {
            font-size: 26px;
          }
        }
        .name ::after {
          content: '：';
        }
        .descript {
          width: calc(100vw - 64px - 135px);
          // background: red;
          text-align: left;
          display: block;
        }
      }
    }
  }
`;
