import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

const items = [
  require('./1@2x.png'),
  require('./2@2x.png'),
  require('./3@2x.png'),
  require('./4@2x.png'),
  require('./5@2x.png'),
  require('./6@2x.png'),
  require('./7@2x.png'),
  require('./8@2x.png'),
  require('./9@2x.png')
];

interface Props {
  className?: string;
}

export default styled((props: Props) => {
  const domRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const mySwiper = new Swiper(domRef.current!, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      autoplay: true,
      pagination: { el: paginationRef.current! }
      // initialSlide: 2
    });
    return () => {
      mySwiper.destroy(true, true);
    };
  }, []);
  return (
    <div className={props.className + ' swiper-container'} ref={domRef}>
      <div className='swiper-wrapper'>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className='swiper-slide'
              style={
                {
                  // backgroundColor: item.color
                }
              }>
              <img src={item} alt={item} />
            </div>
          );
        })}
      </div>
      <div className='swiper-pagination' ref={paginationRef} />
    </div>
  );
})`
  width: 100%;
  height: 400px;
  padding-top: 30px;
  padding-bottom: 31px;
  overflow: hidden;
  .swiper-slide {
    width: 688px;
    height: 418px;
    border-radius: 12px;
    position: relative;
    > img {
      width: 100%;
    }
  }
  .swiper-pagination {
    bottom: 5vw;
    .swiper-pagination-bullet {
      height: 1.5vw;
      width: 1.5vw;
      margin: 0 1vw;
      opacity: 0.5;
      &.swiper-pagination-bullet-active {
        background-color: #08a29a;
        opacity: 1;
      }
    }
  }
`;
