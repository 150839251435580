import styled from 'styled-components';
export default styled.div`
  position: relative;
  padding: 80px 30px;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    overflow: hidden;
    box-shadow: 0px 0.4vw 2vw rgba(0, 0, 0, 0.3);
    z-index: 999;
  }
  > .title {
    font-size: 50px;
    font-weight: 500;
    color: rgba(0, 72, 68, 1);
  }
  > .form {
    padding-top: 40px;
    > .item {
      padding-top: 20px;
      > .key {
        font-size: 28px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        padding-bottom: 13px;
      }
      > .value {
        width: 100%;
        height: 80px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(112, 112, 112, 1);
        border-radius: 4px;

        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);

        > input {
          height: 100%;
          width: 100%;
          line-height: 80px;
          padding: 0 20px;

          outline: none;
          border: none;
        }
        &.select {
          position: relative;
          box-shadow: 0px 0.4vw 6vw rgba(0, 0, 0, 0.3) inset;
          > .select-icon {
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translate(0, -50%);
            width: 16px;
          }
          > select {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
          }
        }
        &.textarea {
          height: 200px;
          > textarea {
            height: 100%;
            width: 100%;

            outline: none;
            border: none;

            padding: 20px;
            box-sizing: border-box;
          }
        }
      }
      &.textarea {
        > .key {
          font-size: 50px;
          font-weight: 500;
          color: rgba(0, 72, 68, 1);
          padding-top: 60px;
          padding-bottom: 40px;
        }
      }
    }
  }
  > .btn {
    margin-top: 60px;
    width: 100%;
    height: 80px;
    font-size: 36px;
    line-height: 80px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(112, 112, 112, 1);
    border-radius: 4px;
    text-align: center;
  }
`;
