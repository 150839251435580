import styled from 'styled-components';
export default styled.div`
  background-color: #f5f5f5;
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    background-color: #fff;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 40px;
      }
      &.graduate {
        > .content {
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding-top: 40px;
          }
        }
      }
    }
  }
  > .ryds {
    width: 720px;
    //overflow: hidden;
    margin: auto;
    box-sizing: border-box;
    > .title {
      font-size: 40px;
      font-weight: 400;
      line-height: 44px;
      color: rgba(0, 0, 0, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 70px 10px 50px 10px;
      > .line {
        height: 2px;
        width: 210px;
        background-color: rgba(112, 112, 112, 1);
      }
    }
    > .list {
      > .item {
        width: 100%;
        padding-bottom: 80px;
        > img {
          width: 100%;
        }
      }
    }
  }
`;
