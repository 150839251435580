import React from 'react';
export const Course = [
  {
    left: '语言课程',
    right: 'Language Study'
  },
  {
    left: '国家义务课程',
    right: 'Essence of Chinese Curriculum'
  },
  {
    left: '艺术感知课程',
    right: 'Vision of Arts',
    content:
      '篆刻、手工、油画、主持、民乐、民族舞、街舞、动漫、阿卡贝拉、尤克里里',
    leftVW: '60vw'
  },
  {
    left: '学术延展课程',
    right: 'Extensive Academic Course'
  },
  {
    left: '体育健康探究',
    right: 'Research of Sports & Health',
    content:
      '高尔夫、橄榄球、壁球、攀岩、击剑、瑜伽、网球、篮球、足球、围棋、心理健康',
    leftVW: '87vw'
  }
];
export const Feature = [
  {
    title: '——30人小班制/一对一家长会',
    content: (
      <>
        <div>
          我们坚信，每个孩子都应得到同样的关爱，不因成绩的高低，让多数人沦为陪读。因此，我们坚持小班制教学，一个班不超过30个孩子。在英语的分层教学上，我们甚至开创了15人左右的小小班教学。
        </div>
        <div className='gap' />
        <div>
          我们给每个家庭召开一对一家长会，每个家长最长可与老师交流2小时。孩子在学校的方方面面，你都应该知道。
        </div>
      </>
    )
  },
  {
    title: '——双班主任',
    content: (
      <>
        <div>
          我们给每个班级配备2个班主任，每位班主任最多只管理15位学生，给予孩子更加精细化的指导与照顾。
        </div>
        <div className='gap' />
        {/* <div>
          我们引入国内研究生阶段才有的导师制管理，为每个孩子指定专属导师，每个导师带领5-6名学生，只为给孩子的关注多一点，再多一点……
        </div> */}
      </>
    )
  },
  {
    title: '——走班教学/私人订制学习计划',
    content: (
      <>
        <div>
          在英语和数学学科上，我们打破传统行政班级设置，进行走班教学，始终能在适合自己的学习节奏中前行。
        </div>
        <div className='gap' />
        <div>
          我们给每个孩子制定独一无二的学习计划，各科老师针对每个学生的情况进行阶段性分析并适时调整。
        </div>
      </>
    )
  },
  {
    title: '——口语纳入考核',
    content: (
      <>
        <div>
          我们将口语考试纳入日常的英语考核，将英语文学鉴赏纳入课程范围，反对只看分数的“哑巴英语”。
        </div>
      </>
    )
  },
  {
    title: '——理化生100%实验室上课',
    content: (
      <>
        <div>
          我们相信，兴趣是最好的老师，和日常的课业相比，动手能力同样重要。所以，我们把教室搬进了实验室，100%的物理、化学、生物课在实验室授课。
        </div>
        <div className='gap' />
        <img className='img' src={require('./feature.png')} alt='' />
      </>
    )
  }
];

export const Outside = [
  {
    title: '● 课外活动',
    items: [
      '汉服设计大赛',
      '体艺文化节',
      '外语文化节',
      '德普吉尼斯',
      '德普爱心基金',
      '书香文化节',
      '水火箭大赛',
      '苹果4S店编程',
      '数学头脑王者'
    ]
  },
  {
    title: '● 学生社团',
    items: [
      '“静等”茶艺社',
      'Rainbow-Line公益社',
      '漫研社',
      'Holy Rock电影社',
      '“画甜时间”美食社',
      '7 Minutes 创意传媒社',
      '体育社',
      '韩社',
      'Alice 乐器社',
      ' 夜莺合唱团'
    ]
  }
];
