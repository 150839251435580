import styled from 'styled-components';
export default styled.div`
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;

  > .wrap {
    max-width: 80%;
    position: fixed;
    left: 60px;
    filter: drop-shadow(0 0 8vw #ccc);
    transform: translateY(60px);
    > .arrow {
      width: 0;
      height: 0;
      border: 20px solid;
      border-color: transparent transparent #fff;
      position: absolute;
      z-index: 1;
      left: 30px;
      top: -15px;
      transform: translate(0, -50%);
      //background-color: red;
    }
    > .content {
      display: inline-block;
      background-color: #fff;
      padding: 30px 30px;

      font-size: 30px;
      font-weight: 400;
      line-height: 50px;
      color: #004844;
    }
  }
`;
export const WrapStyleComponent = styled.div`
  position: relative;
  &.active {
    //border-bottom: 2px solid;
    font-weight: bold !important;
    //text-decoration: underline;
    //border-bottom: 1px solid #ccc;
    color: #004844 !important;
    span {
      //border-bottom: 1px solid #ccc;
    }
  }
  > .icon {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translate(-50%, -50%) rotate(-180deg) scaleY(-1);
    width: 30px;
  }
`;
