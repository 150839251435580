import React from 'react';
export default [];
export const Course = [
  {
    left: '学科英语课程',
    right: 'Cross-subject English'
  },
  {
    left: '国学浸润课程',
    right: 'Absorbing Sinology'
  },
  {
    left: '国家义务课程',
    right: 'National Compulsory Curriculum'
  },
  {
    left: '生涯行知课程',
    right: 'Discovery and Inquiry'
  },
  {
    left: '素质拓展课程',
    right: 'Extra-Curriculum Activity',
    content:
      '高尔夫、游泳、攀岩、壁球、足球、网球、棒球、钢琴、舞蹈、声乐、美术、陶艺',
    leftVW: '80vw'
  }
];
export const Boarding = [
  {
    time: '6:50',
    left: (
      <>
        <div>准时起床</div>
        <div>自己叠被子、 洗漱</div>
      </>
    ),
    right: '赖在床上'
  },
  {
    time: '7:10',
    left: '晨跑',
    right: '爷爷奶奶伺候穿衣洗漱'
  },
  {
    time: '7:40',
    left: '营养早餐',
    right: '到点了还没吃完,匆忙出门'
  },
  {
    time: '8:10',
    left: '晨读',
    right: '磨蹭在上学路上'
  },
  {
    time: '16:00',
    left: '钢琴、体育、思维训练等综合实践课',
    right: '回家路上'
  },
  {
    time: '19:00',
    left: '阅读、选修、生活课程',
    right: '动画片时间'
  },
  {
    time: '20:00',
    left: '寝室文化',
    right: '父母焦急地辅导作业'
  },
  {
    time: '21:15',
    left: '按时就寝',
    right: '睡前磨蹭中'
  }
];
