import 'core-js/es/promise/finally';
import 'core-js';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import init from './inits';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { registerProcessor } from 'pretreatment-styled';
import postcss from 'postcss';

const pxToVw = require('postcss-px-to-viewport')({
  exclude: /antd-mobile/,
  viewportWidth: 750
});

registerProcessor(
  (css: string) =>
    postcss([pxToVw]).process(css, { from: undefined, to: undefined }).css
);

init().then(() => ReactDOM.render(<App />, document.getElementById('root')));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// 需要停用网页的时候，用下面这段
// import React from 'react';
// import ReactDOM from 'react-dom';
// import OutOfService from '@pages/OutOfService';
// ReactDOM.render(<OutOfService />, document.getElementById('root'));
