import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
      }
      &.children {
        > .content {
          padding-top: 20px;
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding: 20px 0;
          }
        }
      }
      &.course {
        > .content {
          > .t1 {
            padding-top: 48px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            text-align: left;
            color: #666;
          }
          > .t2 {
            padding-top: 20px;
            > .item {
              font-weight: 300;
              font-size: 28px;
              line-height: 50px;
              color: #666;
              display: flex;
              justify-content: center;
              align-items: center;
              > .left {
                width: 210px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              > .right {
                padding-left: 20px;
                flex: 1;
                position: relative;
                &:before {
                  content: ' ';
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                  width: 1px;
                  height: 60%;
                  background-color: #666;
                }
              }
            }
          }
        }
      }
      &.english {
        > .content {
          > .t {
            padding-top: 40px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            text-align: left;
            color: #666;
          }
          > .chart {
            padding-top: 20px;
            > .img {
              width: 100%;
              > img {
                width: 100%;
              }
            }
            > .title {
              padding-top: 20px;
              text-align: center;
              font-size: 24px;
              font-weight: 300;
              line-height: 50px;
              color: rgba(0, 72, 68, 1);
            }
          }
        }
      }
      &.sinology {
        > .content {
          padding-top: 20px;
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding: 20px 0;
          }
        }
      }
      &.boarding {
        > .content {
          padding-top: 20px;
          > .t1 {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding: 20px 0;
          }
          > .t2 {
            padding-top: 10px;
            font-size: 28px;
            font-weight: 400;
            line-height: 60px;
            color: rgba(51, 51, 51, 1);
          }
          > .chart {
            padding-top: 20px;
            > .row {
              height: 72px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 20px 0;
              .block {
                float: right;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 170px;
                height: 43px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(159, 184, 167, 1);
                opacity: 1;
                font-size: 30px;
                font-weight: 400;
                line-height: 24px;
                color: rgba(73, 111, 87, 1);
                position: relative;
                &:before {
                  z-index: -1;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  content: ' ';
                  width: 149px;
                  height: 58px;
                  background: rgba(159, 184, 167, 1);
                  opacity: 1;
                }
                &.not {
                  float: left;
                  color: rgba(0, 72, 68, 1);
                  border-color: rgba(0, 72, 68, 1);
                  &:before {
                    background: rgba(0, 72, 68, 1);
                  }
                }
              }
              > .left {
                flex: 1;
                font-size: 22px;
                font-weight: 400;
                line-height: 30px;
                color: rgba(102, 102, 102, 1);
                text-align: right;
              }
              > .middle {
                width: 140px;
                padding: 0 30px;
                box-sizing: border-box;
                > .circle {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 80px;
                  height: 80px;
                  width: 80px;
                  background: rgba(0, 72, 68, 1);
                  font-size: 30px;
                  font-weight: 400;
                  line-height: 46px;
                  color: rgba(255, 255, 255, 1);
                }
              }
              > .right {
                flex: 1;
                font-size: 22px;
                font-weight: 400;
                line-height: 30px;
                color: rgba(0, 72, 68, 1);
                text-align: left;
              }
            }
          }
          > .t3 {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding: 20px 0;
          }
          > .list {
            > .item {
              padding: 20px 0;
              font-size: 28px;
              font-weight: 400;
              line-height: 50px;
              color: rgba(102, 102, 102, 1);
            }
          }
        }
      }
      &.teacher {
        > .content {
          > .t {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            > .link {
              color: rgba(23, 125, 224, 1);
            }
          }
        }
      }
      &.authentication {
        > .content {
          > .t1 {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .t2 {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }
  }
`;
