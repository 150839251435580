import styled from 'styled-components';
export default styled.div`
  width: 720px;
  margin: auto;
  box-sizing: border-box;
  > .title {
    font-size: 50px;
    line-height: 46px;
    font-weight: bold;
    color: rgba(0, 72, 68, 1);
    width: 720px;
    margin: auto;
    padding: 60px 0 30px 20px;
    box-sizing: border-box;
  }
  > .swiper-container {
    width: 100%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 20px;
    > .swiper-wrapper {
      > .swiper-slide {
        padding: 30px 23px 0 23px;
        box-sizing: border-box;
        > .title {
          font-size: 34px;
          font-weight: 400;
          line-height: 50px;
          color: rgba(0, 72, 68, 1);
          margin-top: 60px;
          padding-left: 20px;
          position: relative;
          &:after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            height: 10px;
            width: 10px;
            background: rgba(0, 72, 68, 1);
            content: ' ';
          }
        }
        > .subTitle {
          padding-top: 50px;
          font-size: 30px;
          font-weight: 400;
          line-height: 46px;
          color: rgba(51, 51, 51, 1);
        }
        > .t {
          padding-top: 40px;
          font-size: 28px;
          font-weight: 300;
          line-height: 50px;
          color: rgba(102, 102, 102, 1);
        }
        > .btn {
          margin: 60px auto 40px auto;

          display: flex;
          justify-content: center;
          align-items: center;

          width: 400px;
          height: 70px;
          background: rgba(159, 184, 167, 1);
          border-radius: 10px;

          font-size: 28px;
          font-weight: 300;
          line-height: 50px;
          color: rgba(255, 255, 255, 1);
        }
        > .gap {
          width: 241px;
          height: 4px;
          background: rgba(73, 111, 87, 1);
          margin: auto;
        }
      }
    }
    > .page {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      > .btn {
        width: 42px;
        > img {
          width: 100%;
        }
      }
      > .progress {
        padding: 0 20px;
      }
    }
  }
`;
