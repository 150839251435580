import React from 'react';
import leaderStr from './leaderStr';
import syxxStr from './syxxStr';
import xbczStr from './xbczStr';
import syczStr from './syczStr';
import gjgzStr from './gjgzStr';
import LazyImg from '@components/LazyImg';
const dataFilter = (str: string, images: string[]) => {
  const blocks = str.split('--').map(x => x.replace(x[0], ''));
  return blocks.map((block, index) => {
    const rows = block.split('\n');
    const ideaIndex = rows.findIndex(x => x === 'idea');
    const enIndex = rows.findIndex(x => x === 'descEn');
    const desc =
      ideaIndex === -1 ? rows.slice(1, enIndex) : rows.slice(1, ideaIndex);
    const idea =
      ideaIndex === -1 ? undefined : rows.slice(ideaIndex + 1, enIndex);
    const descEn = rows.slice(enIndex + 1, rows.length);
    const name = rows[0].replace(/\d+/, '');
    return (
      <>
        <div className='photo'>
          <LazyImg src={images[index]} alt='' />
        </div>
        <div className='name'>
          <div className='text'>{name}</div>
        </div>
        <div className='desc'>
          {desc.map((x, index) => {
            return x ? (
              <div key={index}>{x}</div>
            ) : (
              <div key={index}>&nbsp;</div>
            );
          })}
        </div>
        {idea ? <div className='idea'>教育理念：{idea}</div> : undefined}
        <div className='descEn'>
          {descEn.map((x, index) => {
            return x ? (
              <div key={index}>{x}</div>
            ) : (
              <div key={index}>&nbsp;</div>
            );
          })}
        </div>
        <div className='gap' />
      </>
    );
  });
};
export const leader = dataFilter(leaderStr, [
  require('./LeaderImage/01许凌可.jpg'),
  require('./LeaderImage/02孙继先.jpg'),
  require('./LeaderImage/03夏丽琼.jpg'),
  require('./LeaderImage/04孟姜.jpg'),
  require('./LeaderImage/05张韧.jpg'),
  require('./LeaderImage/06赵川.jpg')
]);
export const syxx = dataFilter(syxxStr, [
  require('./SyxxImage/1王波.jpg'),
  require('./SyxxImage/2蒋丽萍.jpg'),
  require('./SyxxImage/3王丹.jpg'),
  require('./SyxxImage/4邓雪.jpg'),
  require('./SyxxImage/6周庆.jpg'),
  require('./SyxxImage/8傅豪.jpg'),
  require('./SyxxImage/9胡瑾.jpg'),
  require('./SyxxImage/10陈果.jpg'),
  require('./SyxxImage/13姜文.jpg')
]);

//双语初中
export const sycz = dataFilter(syczStr, [
  require('./SyczImage/1梅娟.jpg'),
  require('./SyczImage/2杨蝶.jpg'),
  require('./SyczImage/3田雯霞.jpg'),
  require('./SyczImage/4汪国平.png'),
  require('./SyczImage/5付颖.png'),
  require('./SyczImage/6张慧婷.png'),
  require('./SyczImage/7令狐天娇.jpg'),
  require('./SyczImage/8潘奕帆.png'),
  require('./SyczImage/9邱冰莹.png'),
  require('./SyczImage/11RyanDaley.png'),
  require('./SyczImage/12AndreasMikaelGrigorian.png'),
  require('./SyczImage/13RahimKarim.png')
]);

//国际高中部
export const gjgz = dataFilter(gjgzStr, [
  require('./GjgzImage/01陈诗涛.png'),
  require('./GjgzImage/02雷小寒.jpg'),
  require('./GjgzImage/03张立妮.png'),
  require('./GjgzImage/04邓澄.png'),
  require('./GjgzImage/05侯丹.jpg'),
  require('./GjgzImage/06WalayatAli.jpg'),
  require('./GjgzImage/07DavidHylands.png'),
  require('./GjgzImage/08杨成静.png'),
  require('./GjgzImage/09李菁.jpg'),
  require('./GjgzImage/10易睿.png'),
  require('./GjgzImage/11张超.png'),
  require('./GjgzImage/12宋渝航.png'),
  require('./GjgzImage/13齐钰泠.jpg')
]);

// 小班初中
export const xbcz = xbczStr;

// 荣誉导师
export const ryds = [
  {
    img: require('./RydsImage/1.png'),
    link: 'https://mp.weixin.qq.com/s/UDdlrbnH6lA8gqA4_JGebw'
  },
  // { img: require('./RydsImage/2.png') },
  {
    img: require('./RydsImage/3.png'),
    link: 'https://mp.weixin.qq.com/s/RAEW4Y6cHfiEOdidswxK4Q'
  },
  { img: require('./RydsImage/4.png') },
  { img: require('./RydsImage/5.png') }
];
export default [];
