import React, { useState } from 'react';
import styled from 'styled-components';
import LazyImg from '@components/LazyImg';
import { RouteComponentProps } from 'react-router-dom';
interface Props {
  className?: string;
  data: Array<{
    img: string;
    text1: string;
    ext?: React.ReactNode;
    text2: string;
    activeColor: string;
    to?: string;
  }>;
}
export default styled((props: Props & RouteComponentProps<any>) => {
  const [showIndex, setShowIndex] = useState<number | undefined>(undefined);
  return (
    <div className={props.className}>
      {props.data.map((item, index) => {
        return (
          <div
            className='item'
            onClick={() => {
              setShowIndex(index);
              setTimeout(() => {
                if (item.to) {
                  props.history.push(item.to);
                  window.scrollTo(0, 0);
                  localStorage.setItem('fromIndex', 'true');
                } else {
                  alert('开发中');
                }
              }, 500);
            }}
            key={item.img}>
            <LazyImg src={item.img} alt='tag' key={item.img} />
            <div
              className='mask'
              style={{
                opacity: showIndex === index ? 0.7 : undefined,
                backgroundColor:
                  showIndex === index ? item.activeColor : undefined
              }}>
              <div className='text1'>{item.text1}</div>
              <div className='text2 fontEn'>{item.text2}</div>
            </div>
            <div className={'ext'}>{item.ext}</div>
          </div>
        );
      })}
    </div>
  );
})`
  width: 690px;
  margin: auto;
  > .item {
    height: 413px;
    width: 100%;
    margin-bottom: 31px;
    &:last-child {
      margin-bottom: 0;
    }
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    > img {
      height: 100%;
      width: 100%;
    }
    > .mask {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.56);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 34px;
      box-sizing: border-box;
      transition: all 0.5s ease-in-out;
      > .text1 {
        font-weight: normal;
        font-size: 30px;
        letter-spacing: 0.06em;
        line-height: 41px;
        color: #fff;
      }
      > .text2 {
        font-weight: bold;
        font-size: 38px;
        letter-spacing: 0.05em;
        line-height: 39px;
        color: #fff;
        padding-top: 10px;
      }
    }
    > .ext {
      position: absolute;
      right: 20px;
      bottom: 40px;
      color: #fff;
      font-size: 20px;
    }
  }
`;
