import React, { useEffect, useRef } from 'react';

export default (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const cb = (res: IntersectionObserverEntry[]) => {
      res.forEach(item => {
        if (item.intersectionRatio > 0) {
          item.target.setAttribute('src', props.src);
          item.target.removeAttribute('data-src');
          ob.disconnect();
        }
      });
    };
    const ob = new IntersectionObserver(cb);
    if (imgRef.current) {
      ob.observe(imgRef.current);
      cb(ob.takeRecords());
    }
    return () => {
      ob.disconnect();
    };
  });

  return (
    <img {...props} src='' data-src={props.src} ref={imgRef} alt={props.alt} />
  );
};
