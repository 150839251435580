const fn = () => {
  const imgs = document.querySelectorAll('img');
  return Promise.all(
    [].map.call(imgs, (node: HTMLImageElement) => {
      node.addEventListener('click', e => {
        e.preventDefault();
      });
      node.addEventListener('contextmenu', e => {
        e.preventDefault();
      });
      return '';
    })
  );
};

window.addEventListener('load', fn);

window.addEventListener('hashchange', fn);

export default fn;
