import React, { useEffect, useRef } from 'react';
import StyledComponent from './index.style';
import Swiper from 'swiper';

const imgs = [
  require('./01首页轮播图1.jpg'),
  require('./02首页轮播图2.jpg'),
  require('./03hurun.jpg')
];
export default () => {
  const domRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<any>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    swiperRef.current = new Swiper(domRef.current!, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      // autoplay: true,
      pagination: { el: paginationRef.current! }
    });
    return () => {
      swiperRef.current!.destroy(true, true);
    };
  }, []);
  return (
    <StyledComponent>
      <div className='swiper-container' ref={domRef}>
        <div className='swiper-wrapper'>
          {imgs.map(img => {
            return (
              <div key={img} className='swiper-slide'>
                <img src={img} alt='' />
              </div>
            );
          })}
        </div>
        <div className='swiper-pagination' ref={paginationRef} />
      </div>
    </StyledComponent>
  );
};
