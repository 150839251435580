import React from 'react';
import Header from '@components/Header';
import Pop from '@components/Pop';
import StyledComponent from './index.style';
import { Course } from '@pages/BilingualJunior/Data';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps<any>) => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='双语初中'
        titleEn='Bilingual Junior'
        content={
          <>
            <div>低龄留学不放心，</div>
            <div>中国优势不放弃，</div>
            <div>无缝衔接国际高中，</div>
            <div>让孩子早一步“观世界”。</div>
          </>
        }
      />
      <div className='content '>
        {/* <div className='section vs'>
          <div className='title'>双语初中与传统初中的区别</div>
          <div className='content'>
            <div className='t1'>
              德普双语初中，以培养学生顺利衔接国际高中课程，进入全球一流名校为目标，夯实中国基础教育的同时，融合剑桥学科英语知识，培养懂中国懂世界的创变者。
            </div>
            <div className='vs'>
              <div className='left'>
                <div className='circle'>
                  <div className='l1'>
                    <img src={require('./house.png')} alt='house' />
                  </div>
                  <div className='l2'>传统初中</div>
                </div>
              </div>
              <div className='middle'>VS</div>
              <div className='right'>
                <div className='circle depu'>
                  <div className='l1'>DEPU</div>
                  <div className='l2'>双语初中</div>
                </div>
              </div>
            </div>
            <div className='chart'>
              {vs.map((item, index) => {
                return (
                  <div className='row' key={index}>
                    <div className='left'>
                      <span>{item.left}</span>
                    </div>
                    <div className='right'>
                      <span>{item.right}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className='section course'>
          <div className='title'>德普双语初中课程</div>
          <div className='content'>
            <div className='t1'>
              一束普通的白光，通过棱镜的折射，便能幻化为绚丽的七彩光。我们希望，双语初中的棱镜课程，也能还原孩子原本的光彩。
            </div>
            <div className='t2'>棱镜课程-LENSES</div>
            <div className='t3'>
              {Course.map(item => {
                return (
                  <Pop
                    className='item'
                    key={item.right}
                    content={item.content}
                    leftVW={item.leftVW}>
                    <div className='left'>
                      {item.left.split('').map((x, index) => {
                        return <span key={index}>{x}</span>;
                      })}
                    </div>
                    <div className='right'>{item.right}</div>
                  </Pop>
                );
              })}
            </div>
            <div className='subTitle'>——国学浸润课程</div>
            <div className='chart'>
              <div className='t'>
                我们不想孩子只会拿刀叉，却忘了筷子的艺术。为此，我们用义理之学（哲学）、经世之学（政治学、经济学）、词章之学（文学）、考据之学（史学）来浸润孩子，希望他们懂得“仁爱之心、顺势而为、变通之道、知行合一”，在一粥一饭里，做愉悦丰盈的故乡人。
              </div>
            </div>
          </div>
        </div>
        <div className='section teacher'>
          <div className='title'>精英师资</div>
          <div className='content'>
            <div className='t'>
              在这里，会说英语的外国人不等于外教，作为德普的外教，专业对口的科班出身是基本要求；
            </div>
            <div className='t'>
              在这里，会说英语的某科任老师不等于双语老师，作为德普的双语老师，国内外双重教育背景几乎已成标配；
            </div>
            <div className='t'>
              在这里，大量的教师拥有硕士学位和海归背景，还有一批资深的国际教育专家，只为让你的孩子站得更高，走得更远！
            </div>
            <div className='t'>
              <span
                className='link'
                onClick={() => {
                  props.history.push('/elitefaculties?t=3');
                  window.scrollTo(0, 0);
                  localStorage.setItem('fromIndex', 'true');
                }}>
                点击查看师资详细介绍
              </span>
            </div>
          </div>
        </div>
        {/* <div className='section authentication'>
          <div className='title'>国际认证</div>
          <div className='content'>
            <div className='t1'>
              ——我们得到了来自国际国内权威机构的认可，除剑桥大学国际考评部（Cambridge
              International）官方认证的“重庆剑桥国际中心”，全球统考考点和西南地区首家剑桥教师职业发展中心（PDQ），德普还是：
            </div>
            <div className='t2'>
              <div>
                美国大学理事会（The College Board）认证学校，授权开设AP课程
              </div>
              <div>
                爱德思学术资质及考试中心(Edexcel Academic Qualifications)
              </div>
              <div>
                英国教育文化协会认证普思考试示范学校（APTIS Model School）
              </div>
              <div> 2019中国新锐国际学校</div>
              <div> 2019剑桥国际东亚区大会承办学校</div>
              <div> 重庆市外商投资企业协会战略合作伙伴</div>
              <div> 新加坡重庆商会战略合作伙伴</div>
            </div>
            <div className='subTitle'>
              ——我们提供大学升学的绿色通道，给学生更多的保障：
            </div>
            <div className='t2'>
              <div>加拿大多伦多大学绿色通道</div>
              <div>加拿大约克大学绿色通道</div>
              <div>日本立命馆亚洲太平洋大学绿色通道</div>
            </div>
            <div className='subTitle'>
              ——我们也是众多国际主流学科竞赛的赛点，为学生申请世界顶尖名校提供了最大的可能性，包括：
            </div>
            <div className='t2'>
              <div>澳大利亚科学竞赛BSC</div>
              <div>美国化学竞赛USNCO</div>
              <div>加拿大化学竞赛CCC</div>
              <div>加拿大化学奥赛CCO</div>
              <div>加拿大初中化学奥赛BCCO</div>
              <div>英国化学奥赛UKChO</div>
              <div>澳大利亚化学奥赛ASOC</div>
              <div>美国少年化学家挑战赛YBTC</div>
              <div>美国计算机科学联赛ACSL</div>
              <div>澳大利亚数学竞赛AMC</div>
              <div>美国物理杯AAPT</div>
              <div>英国物理奥赛BPhO</div>
              <div>袋鼠数学竞赛MK</div>
              <div>阿斯丹商赛 ASDAN</div>
              <div>美国数学竞赛AMC/AIME</div>
              <div>全美经济挑战赛NEC</div>
              <div>滑铁卢大学数学竞赛</div>
              <div>欧几里得数学竞赛</div>
            </div>
          </div>
        </div> */}
      </div>
    </StyledComponent>
  );
};
