import React, { useCallback, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useApi from '@configs/apis';

const animationDuration = 300;
const pageIn = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    transform:translate(-50%, -50%) scale(1);
  }
`;
const pageOut = keyframes`
  from {
    transform: translate(-50%, -50%)scale(1);
  }

  to {
    transform:translate(-50%, -50%) scale(0);
  }
`;
const maskIn = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }

  to {
    background: rgba(0, 0, 0, 0.67);
  }
`;
const maskOut = keyframes`
  from {
    background: rgba(0, 0, 0,0.67 );
  }

  to {
    background: rgba(0, 0, 0, 0);
  }
`;
interface Props {
  className?: string;
  onClose: () => void;
}
export default styled((props: Props) => {
  const [items, setItems] = useState([
    { key: 'studentName', placeholder: '学生姓名', type: 'input', value: '' },
    { key: 'contactPhone', placeholder: '联系电话', type: 'number', value: '' },
    {
      key: 'schoolAndClass',
      placeholder: '在读学校与班级',
      type: 'input',
      value: ''
    },
    {
      key: 'department',
      placeholder: '请选择您所申请学部',
      type: 'select',
      value: '',
      children: [
        { label: '双语寄宿小学', key: '1', isActive: false },
        { label: '双语初中', key: '2', isActive: false },
        { label: '小班初中', key: '3', isActive: false },
        {
          label: '国际高中衔接班（仅针对初二在读学生）',
          key: '4',
          isActive: false
        },
        { label: '国际高中', key: '5', isActive: false }
      ],
      isActive: false
    },
    {
      key: 'channel',
      placeholder: '了解渠道',
      type: 'select',
      value: '',
      children: [
        { label: '朋友推荐', key: '1', isActive: false },
        { label: '家住附近', key: '2', isActive: false },
        { label: '网络搜索', key: '3', isActive: false },
        { label: '学校/老师推荐', key: '4', isActive: false },
        { label: '其他', key: '5', isActive: false }
      ],
      isActive: false
    }
  ]);
  const [isPageOut, setIsPageOut] = useState(false);
  const maskRef = useRef<HTMLDivElement>(null);
  const handleSubmit = useCallback(() => {
    const values = items.map(x => {
      let value = x.value;
      if (x.type === 'select') {
        const child = x.children?.find(x => x.isActive);
        if (child) {
          value = child.label;
        } else {
          value = '';
        }
      }
      return { key: x.key, value };
    });
    const value = {
      studentName: values.find(x => x.key === 'studentName')?.value,
      contactPhone: values.find(x => x.key === 'contactPhone')?.value,
      schoolAndClass: values.find(x => x.key === 'schoolAndClass')?.value,
      department: values.find(x => x.key === 'department')?.value,
      channel: values.find(x => x.key === 'channel')?.value
    };
    if (!value.studentName) {
      alert('请输入学生姓名');
      return;
    }
    if (!value.contactPhone) {
      alert('请输入联系电话');
      return;
    }
    if (!/^1\d{10}$/.test(value.contactPhone)) {
      alert('联系电话格式不正确');
      return;
    }
    if (!value.schoolAndClass) {
      alert('请输入在读学校与班级');
      return;
    }
    if (!value.department) {
      alert('请输选择申请学部');
      return;
    }
    if (!value.channel) {
      alert('请输选择了解渠道');
      return;
    }
    useApi.create.request(value).then(() => {
      alert('您的信息已提交成功，我们会尽快联系您!');
      props.onClose();
    });
  }, [items, props]);
  return (
    <div
      className={isPageOut ? `#{props.className} maskOut` : props.className}
      ref={maskRef}
      onClick={e => {
        if (maskRef.current === e.target) {
          setIsPageOut(true);
          setTimeout(() => {
            props.onClose();
          }, animationDuration);
        }
      }}>
      <div className={isPageOut ? 'body pageOut' : 'body'}>
        <div className='title'>
          <img src={require(`./title.png`)} alt='' />
        </div>
        <div className='content'>
          {items.map(item => {
            let value = item.value;
            let activeChild:
              | { label: string; key: string; isActive: boolean }
              | undefined;
            if (item.type === 'select') {
              activeChild = (item.children || []).find(x => x.isActive);
              if (activeChild) {
                value = activeChild.label;
              } else {
                // value = item.placeholder;
              }
            }
            return (
              <div className='form-item' key={item.key}>
                <input
                  value={value}
                  onChange={e => {
                    item.value = e.target.value;
                    setItems([...items]);
                  }}
                  placeholder={item.placeholder}
                  readOnly={item.type === 'select'}
                  onClick={() => {
                    if (item.type === 'select') {
                      const isActive = !item.isActive;
                      items.forEach(x => {
                        x.isActive = false;
                      });
                      item.isActive = isActive;
                      setItems([...items]);
                    }
                  }}
                />
                {item.type === 'select' && (
                  <div
                    className={item.isActive ? 'active expendBtn' : 'expendBtn'}
                    onClick={() => {
                      if (item.type === 'select') {
                        const isActive = !item.isActive;
                        items.forEach(x => {
                          x.isActive = false;
                        });
                        item.isActive = isActive;
                        setItems([...items]);
                      }
                    }}>
                    <img src={require('./arrow.svg')} alt='arrow' />
                  </div>
                )}
                {item.type === 'select' && (
                  <div
                    className={item.isActive ? 'active expend' : 'expend'}
                    style={{
                      height: item.isActive
                        ? (item.children || []).length * 8 + 'vw' //css中写死的高度
                        : 0
                    }}>
                    {(item.children || []).map(child => {
                      return (
                        <div
                          className={
                            activeChild === child ? 'active item' : 'item'
                          }
                          key={child.key}
                          onClick={() => {
                            (item.children || []).forEach(x => {
                              x.isActive = false;
                            });
                            child.isActive = true;
                            setItems([...items]);
                            item.isActive = false;
                          }}>
                          {child.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className='btns'>
          <div className='btn' onClick={handleSubmit}>
            提交
          </div>
        </div>
      </div>
    </div>
  );
})`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.67);
  animation: ${maskIn} ${animationDuration}ms ease-in-out 1;
  z-index: 999;
  &.maskOut {
    animation: ${maskOut} ${animationDuration}ms ease-in-out 1;
  }
  > .body {
    height: 1034px;
    width: 714px;
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    background: #fff;
    animation: ${pageIn} ${animationDuration}ms ease-in-out 1;
    &.pageOut {
      animation: ${pageOut} ${animationDuration}ms ease-in-out 1;
    }
    > .title {
      text-align: center;
      > img {
        width: 592px;
        margin: 50px auto;
      }
    }
    > .content {
      > .form-item {
        margin: 30px auto;
        width: 634px;
        height: 83px;
        line-height: 83px;
        border: 2px solid #777777;
        position: relative;
        box-sizing: border-box;
        > input {
          height: 100%;
          width: 100%;
          padding: 0 20px;
          font-size: 28px;
          outline: 0;
          display: block;
          border: 0;
        }
        > .expendBtn {
          height: 100%;
          width: 83px;
          position: absolute;
          right: 0;
          top: 0;
          text-align: center;
          border-left: 2px solid #777777;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 30px;
            transition: all 0.3s ease-in-out;
          }
          &.active {
            > img {
              transform: rotate(-180deg);
            }
          }
        }
        .expend {
          border: 0;
          width: 634px;
          box-sizing: border-box;
          position: absolute;
          left: -2px;
          top: 100%;
          background: #fff;
          overflow: hidden;
          z-index: 999;
          padding: 0 20px;
          line-height: 8vw;
          transition: height 0.3s ease-in-out;
          > .item {
            height: 8vw;
          }
          &.active {
            border: 2px solid #777777;
          }
        }
      }
    }
    > .btns {
      padding-top: 20px;
      > .btn {
        margin: auto;
        width: 300px;
        height: 83px;
        line-height: 83px;
        border: 2px solid #777777;

        font-size: 30px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
