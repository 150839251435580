import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .block {
    width: 100%;
    padding-top: 40px;
    > img {
      width: 100%;
    }
  }
  > .blocks {
    > .block {
      width: 710px;
      margin: 100px auto 0 auto;
      background: rgba(255, 255, 255, 1);
      > img {
        width: 100%;
      }
    }
  }
  > .more {
    margin: auto;
    padding: 90px 0 0 0;
    text-align: center;
    > .text {
      color: #1f5e5a;
      font-size: 24px;
      width: 100%;
      padding-bottom: 20px;
      font-weight: bold;
    }
    > img {
      width: 40px;
      display: inline-block;
    }
  }
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 40px;
      }
      &.graduate {
        > .content {
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding-top: 40px;
          }
          > .title {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(0, 72, 68, 1);
            text-align: center;
          }
          > .table {
            font-size: 22px;
            font-weight: 300;
            line-height: 16px;
            color: rgba(102, 102, 102, 1);
            border: none;
            > .row {
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: 1px solid rgba(159, 184, 167, 1);
              > .col {
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-right: 1px solid rgba(159, 184, 167, 1);
                &:nth-child(1) {
                  border-left: 1px solid rgba(159, 184, 167, 1);
                  width: 350px;
                }
                &:nth-child(2) {
                  width: 300px;
                  padding-left: 30px;
                  text-align: left;
                }
                &:nth-child(3) {
                  width: 183px;
                }
                &:nth-child(4) {
                  width: 127px;
                }
              }
              &:nth-child(1) {
                border-bottom-width: 0;
                border-top-width: 0;
                background-color: rgba(159, 184, 167, 1);
                > .col {
                  border-right-color: rgba(255, 255, 255, 1);
                  color: #fff;
                  &:last-child {
                    border-right-color: rgba(159, 184, 167, 1);
                  }
                }
              }
            }
          }
          > .table1 {
            font-size: 22px;
            font-weight: 300;
            line-height: 16px;
            color: rgba(102, 102, 102, 1);
            border: none;
            > .row {
              display: flex;
              justify-content: center;
              align-items: stretch;
              border-bottom: 1px solid rgba(159, 184, 167, 1);
              > .col {
                min-height: 50px;
                line-height: 50px;
                text-align: center;
                border-right: 1px solid rgba(159, 184, 167, 1);
                &:nth-child(1) {
                  border-left: 1px solid rgba(159, 184, 167, 1);
                  width: 123px;
                  display: flex;
                  align-items: center;
                  padding-left: 30px;
                }
                &:nth-child(2) {
                  padding-left: 30px;
                  text-align: left;
                  flex: 1;
                }
              }
              &:nth-child(1) {
                border-bottom-width: 0;
                border-top-width: 0;
                background-color: rgba(159, 184, 167, 1);
                > .col {
                  border-right-color: rgba(255, 255, 255, 1);
                  color: #fff;
                  &:last-child {
                    border-right-color: rgba(159, 184, 167, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
