import React, { useEffect, useRef } from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';
import Slider from './Slider';
import {
  leader,
  syxx,
  xbcz,
  sycz,
  gjgz,
  ryds
} from '@pages/EliteFaculties/Data';
import { RouteComponentProps } from 'react-router-dom';
import { animateScroll } from '@src/App';

export default (props: RouteComponentProps<any>) => {
  const domRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!domRef.current) {
      return;
    }
    const match = props.location.search.match(/t=(\d)/);
    if (match && match[1]) {
      const index = parseInt(match[1]);
      const els = domRef.current.querySelectorAll('[data-index]');
      if (els) {
        const el = els[index];
        animateScroll(el as HTMLElement, 0, 'auto');
      }
    }
  }, [props.location.search]);
  return (
    <StyledComponent ref={domRef}>
      <Header
        img={require('./header.png')}
        title='精英师资'
        titleEn='Elite Faculties'
        content={
          <>
            <div>在德普</div>
            <div>教师不仅仅是一份工作，</div>
            <div>更是一个梦想，一种情怀</div>
          </>
        }
      />
      <div className='content '>
        <div className='section graduate'>
          <div className='title' />
          <div className='content'>
            <div className='t'>
              我们不仅是在传授知识，更是在改变每一个孩子，进而改变这个世界。
            </div>
            <div className='t'>
              我们要培养的不是应试机器，而是情感健全、能够自我实现的人。
            </div>
            <div className='t'>
              <div>他们来自不同的大学、不同的国家和文化：</div>
              <div>
                <div>
                  浙大、北师大、川大、宾夕法尼亚大学、南洋理工、英属哥伦比亚、曼彻斯特大学、利物浦大学、纽约大学……
                </div>
                <div>中国、美国、英国、加拿大、新加坡、巴基斯坦……</div>
              </div>
            </div>
            <div className='t'>
              他们是闪耀着名师光芒的国务院特殊教育津贴专家，是硕果累累的教育名师；
            </div>
            <div className='t'>他们怀揣梦想，三尺讲台指点江山。</div>
            <div className='t'>他们深信，一个孩子就是一个世界。 </div>
            <div className='t'>他们愿意用心去陪伴， </div>
            <div className='t'> 帮助每一个孩子通向属于自己的星辰大海。</div>
          </div>
        </div>
      </div>
      <Slider items={leader} title='校领导' index={0} />
      <Slider items={syxx} title='双语小学部' index={1} />
      <Slider items={xbcz} title='小班初中部' index={2} />
      <Slider items={sycz} title='双语初中部' index={3} />
      <Slider items={gjgz} title='国际高中部' index={4} />
      <div className='ryds' data-index={5}>
        <div className='title'>
          <div className='line' />
          <div className='text'>荣誉导师</div>
          <div className='line' />
        </div>
        <div className='list'>
          {ryds.map(item => {
            return (
              <div
                key={item.img}
                className='item'
                onClick={() => {
                  if (item.link) {
                    window.open(item.link);
                  }
                }}>
                <img src={item.img} alt='' />
              </div>
            );
          })}
        </div>
      </div>
    </StyledComponent>
  );
};
