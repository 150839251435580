import axios from 'axios';
import storage from '@utils/storage';
import apiHost from '@src/configs/apiHost';
import { Toast } from 'antd-mobile';

const instance = axios.create({
  baseURL: apiHost
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  async (error = {}) => {
    const {
      response,
      response: { data: { message = '网络请求失败' } = {}, status = 200 } = {}
    } = error;
    if ([401, 403].includes(status)) {
      storage.clear();
    }
    if (message) {
      Toast.offline(message + '');
    }
    return Promise.reject(response);
  }
);

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${storage.get('token')}`;
  return config;
});

export default instance;
