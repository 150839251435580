import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import LazyImg from '@components/LazyImg';

interface Props {
  text1: string;
  text2: string;
}
export default styled(
  (props: React.VideoHTMLAttributes<HTMLVideoElement> & Props) => {
    const [isPlay, setIsPlay] = useState(false);
    const [canPlay, setCanPlay] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
      <div className={props.className}>
        {!isPlay ? <LazyImg src={props.poster} alt='tag' /> : undefined}
        <video
          {...props}
          ref={videoRef}
          playsInline={true}
          onCanPlay={() => {
            setCanPlay(true);
          }}
          preload='preload'
          style={{
            visibility: isPlay ? undefined : 'hidden'
          }}
          x5-video-player-type='h5-page'
        />
        {!isPlay ? (
          <div className='mask'>
            <div className='text1'>{props.text1}</div>
            <div className='text2'>{props.text2}</div>
          </div>
        ) : (
          undefined
        )}

        {!isPlay && canPlay ? (
          <img
            className='playBtn'
            src={require('./播放.svg')}
            alt='play'
            onClick={() => {
              if (videoRef.current) {
                setIsPlay(true);
                videoRef.current.play();
              }
            }}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }
)`
  width: 690px;
  height: 413px;
  margin: auto auto 31px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  > img {
    height: 100%;
    width: 100%;
  }
  > .mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.56);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 34px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    > .text1 {
      font-family: 'Source Han Sans SC';
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      color: #fff;
    }
    > .text2 {
      padding-top: 10px;
      font-family: 'Source Han Sans SC';
      font-weight: normal;
      font-size: 28px;
      text-align: center;
      color: #fff;
    }
  }
  > video {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  > .playBtn {
    height: 188px;
    width: 188px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
