export default `
01梅娟 May
语文高级教师
曾担任重点中学德育副主任、语文教研组组长、
课程评估中心成员、语文学科指导老师、班主任指导老师等。
任教初中语文14年，昆明市区级骨干教师 市级课堂教学竞赛一等奖
市级初中语文教学技术能手          
教育格言：教育不是灌满一桶水，而是点燃一团火！
descEn
Senior Chinese Teacher
was in charge of student moral education, head of Chinese teaching team, member of syllabus evaluation centre, director of Chinese teaching and homeroom teachers in an established school
She has been teaching junior high school Chinese for 14 yearsTeaching 
Kunming municipal level backbone teacher 
First prize in the municipal classroom teaching competition  
Municipal junior high school Chinese teaching technology expert

Motto: Education is not the filling of a bucket, but the lighting of a fire.
--
02杨蝶 Wendy
数学教师
重庆市高级教师
荣获AMC8美国数学竞赛优秀指导教师
荣获 “希望杯”全国数学竞赛“优秀教练员”
荣获中学数学论文全国一等奖
荣获省级数学QC课题优秀奖
教育箴言：用欣赏的眼光看待每一位学生！

descEn
Math Teacher
Chongqing Senior Teacher
Outstanding Instructor of AMC8 American Mathematics Competition
"Excellent Coach" for the "Hope Cup" National Mathematics Competition 
First prize winner in National high school mathematics paper competition
Excellence award winner in the provincial mathematics QC subject 
Education Motto: Look at every student with appreciation.
--
03田雯霞 Shirley
双语商科老师
美国加州州立大学-金融&会计双学位
美国Beta Gamma Sigma Membership –荣获商学院最高荣誉会员，优秀毕业⽣荣誉 Cum Laude （GPA>3.7)
曾就职德勤财务咨询师
剑桥国际英语教师资格证-TKT 
2021届毕业生IG统考得A率百分之四十
指导学生参加全美商业挑战赛（BPA）获得全国第三、全球第五、国际青年领袖财商峰会（U-FEB）-最佳投资战略展示金奖
指导校内商业挑战赛（ibusiness challenge）冠军
被组委会评为全美经济挑战赛和商业全能挑战赛的优秀教练
descEn
Bilingual Business Teacher
Bachelor degree of Accounting and Finance of California state university 
Gamma Sigma Membership 
Overseas work experience
Financial Analyst-Deloitte 
Cambridge International Education English Teacher Certificate- TKT
Excellent coach of NEC/BPA
--
04汪国平 Gavin Wang
双语数学教师、重庆市一级教师、
重庆大学数学与应用数学学士、统计学硕士
在读博士
从事数学教学7年，教学经验丰富。
曾获得第十四届重庆市基础教育课程改革征文大赛一等奖；重庆市中学数学教育教学优秀论文交流评选活动中荣获三等奖；澳大利亚数学竞赛优秀指导教师奖。
教育箴言：谦逊、激情、持续不断的终生学习；不着急，不害怕；每天改变一点点，每天一点点改变。
descEn
Bilingual Math teacher， Chongqing First-grade teacher,
Chongqing University: Bachelor of Mathematics and Applied Mathematics, Master of Statistics
Ph.D. student
7 years teaching experience、Rich teaching experience
Won the first prize of the 14th ChongqingC Basic Education Curriculum Reform Essay Competition; Won the third prize in the hongqing Middle School Mathematics Education and Teaching Excellent Paper Exchange and Selection Activity; Outstanding Coach of Australian Mathematics Competition
Education motto: humble, passionate, continuous lifelong learning; don’t worry, don’t be afraid; change a little bit every day, and a little bit every day.
--
05付颖 Faye
英语教师
毕业院校：伦敦大学学院
CELTA 英语外语教学证书 持有者
希望之星、21世纪演讲比赛、全国中学生能力竞赛优秀指导老师
教育格言：学无止境
descEn
English Teacher
Master of Teaching English to Speakers of Other Languages (TESOL)  of University College London(UCL)
Holder of CELTA certificate
Excellent Supervisor of Star of Outlook Speech Contest, China Daily “21st Century Cup”Speech Contest and NEPTS.
Teaching motto: It's never too late to learn.
--
06张慧婷 Lily
英语教师
爱丁堡大学教育硕士
剑桥英语教学能力证书TKT 证书持有者
重庆市一级教师
希望之星、常春藤IVY英语口语全球赛优秀指导老师
全国中学生英语竞赛一等奖优秀指导教师
教育格言：亦师亦友、度人度己。
descEn
English Teacher
Master of Teaching English to Speakers of Other Languages (TESOL) of University of Edinburgh 
Holder of TKT certificate
Chongqing First-grade teacher
Teaching motto: “Teachers should strive to enable their students to discover the source of their own interests and let them experience their own labor and achievements in this discovery process.”
--
07令狐天娇 Linn
基础英语教师
英国约克大学应用语言学硕士
CELTA证书持有者；剑桥英语教学能力证书TKT 证书持有者
全国中学生英语竞赛一等奖优秀指导教师
教育格言：以教人者教己，在劳力上劳心
descEn
General English Teacher
Master of Applied Linguistics of University of York
Holder of CELTA certificate and TKT certificate
The Excellent Instructor of National Competition
Teaching motto: Teaching should lead by example; Education should pay attention to practice
--
08潘奕帆 Molly
英语老师
曼彻斯特大学教育学硕士
剑桥英语教学能力证书TKT 证书持有者
重庆市一级教师
全国中学生英语竞赛一等奖优秀指导教师
教师格言：教育是一门艺术，它注重心灵沟通，它需要快乐的心
和智慧的爱。
descEn
English Teacher
Master of Education of University of Manchester
Holder of TKT certificate
Chongqing First-grade teacher
Education is an art, it pays attention to the heart communication, it needs to be a happy heart and the love of wisdom.
--
09邱冰莹 Elsie
英语教师
伦敦大学学院（UCL）对外英语教学硕士
四川大学英语语言与文学学士
教育格言：用欣赏的眼光看待每一个学生
descEn
English Teacher
Master of Teaching English to Speakers of Other Languages (TESOL)  of University College London
Bachelor of Englisn Language and Literature of Suchuan Uniiversity
Teaching motto: Appreciate every single student.
--
11Ryan Daley
English Teacher
Bachelor Degree of University of Rochester
Holder of TEFL and TKT certificates
Holder of Trinity CertTESOL and CertPT certificates
2019 Sina Weibo Star Teacher Competition: National Winner
Teaching Motto: Today's hard work makes a better future.
descEn
--
12Andreas Mikael Grigorian

Bilingual Junior English Teacher
Bachelor's Degree From University of West London
Holder of TEFL certificate
Trilingual speaker and practicing Chinese
Teaching Motto : Teaching is a passion, to contribute to a greater cause.
descEn
--
13Rahim Karim 
Bilingual Junior English Teacher 
Bachelor's Degree From Nipissing University 
Holder Of TEFL And TKT Certificates 
Holder Of Cambridge University CELTA Certification 
Teaching Motto: Do Or Do Not. There Is No Try.
descEn
`;
