import React from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='国际高中衔接班'
        titleEn='IGB Class'
        content={<></>}
      />
      <div className='content '>
        <div className='section what'>
          <div className='title'>什么是IGB？</div>
          <div className='content'>
            <div className='t'>
              IGB，即为国际高中衔接班。它是专门为“有出国留学意向，打算今后就读国际高中的学生”量身打造的，为期一年的国际高中课程衔接班。
            </div>
            <div className='t'>
              IGB英文为IG
              Bridge，意为连接、通向IGCSE课程（来源于剑桥大学国际考评部）的桥梁。
            </div>
            <div className='t'>
              我们希望，通过在IGB一年的学习，同学们能够完成中国基础教育与国际化教育之间的衔接，以便无缝适应即将到来的国际高中学习。
            </div>
          </div>
        </div>
        <div className='section why'>
          <div className='title'>为什么要上IGB？</div>
          <div className='content'>
            {/* <div className='t'>
              既然初中毕业后也能读国际高中，那为什么还要在初三时选择IGB呢？这样选择有什么优势呢？
            </div> */}
            <div className='subTitle'>——更多时间与机会</div>
            <div className='t'>
              你将有更多的时间参加课外实践活动，学术准备和附加考试等，为申请国外大学做准备。
            </div>
            <div className='subTitle'>——更易适应</div>
            <div className='t'>
              进入IGB，会让你提前适应即将到来的国际高中的全英文语境下的学习氛围和节奏，缩短高中的不适应期。
            </div>
            <div className='subTitle'>——更多机会</div>
            <div className='t'>
              作为国际高中的衔接预备班，进入IGB，你将多一年时间学习高中国际课程，将拥有更多的学习时间和考试机会。为高中阶段争取到更多考试机会冲击世界名校。
            </div>
          </div>
        </div>
        <div className='section course'>
          <div className='title'>IGB都开设了哪些课程？</div>
          <div className='content'>
            <div className='t'>
              我们提前学习了IGCSE课程的数学、化学、商科等课程，认真学习英语，为留学做准备；开始接触升学指导，思考自己未来的路；也在国学中浸润，在一粥一饭中，做丰盈愉悦的故乡人。
            </div>
            <div className='subTitle'>衔接班核心课程</div>
            <div className='table'>
              <div className='row'>
                <div className='col'>IGCSE数学</div>
                <div className='col'>IGCSE化学</div>
              </div>
              <div className='row'>
                <div className='col'>IGCSE物理</div>
                <div className='col'>IGCSE商科</div>
              </div>
              <div className='row'>
                <div className='col'>基础英语</div>
                <div className='col'>英语读写</div>
              </div>
              <div className='row'>
                <div className='col'>英语听说</div>
                <div className='col'>升学指导</div>
              </div>
              <div className='row'>
                <div className='col'>大语文</div>
                <div className='col'>国学</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
