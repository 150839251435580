import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
      }
      &.vs {
        > .content {
          > .t1 {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
          > .vs {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            .circle {
              width: 162px;
              height: 162px;
              border: 2px solid rgba(0, 72, 68, 1);
              border-radius: 50%;
              opacity: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              > .l1 {
                font-size: 40px;
                font-weight: 400;
                line-height: 53px;
                color: rgba(255, 255, 255, 1);
                font-family: STFangsong;
                > img {
                  width: 60px;
                }
              }
              > .l2 {
                font-size: 28px;
                font-weight: 400;
                line-height: 36px;
                color: rgba(0, 72, 68, 1);
              }
              &.depu {
                background: rgba(159, 184, 167, 1);
                border-color: rgba(159, 184, 167, 1);
                > .l2 {
                  color: rgba(255, 255, 255, 1);
                }
              }
            }
            > .left {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
            > .middle {
              font-size: 32px;
              font-weight: 400;
              line-height: 36px;
              color: rgba(0, 72, 68, 1);
            }
            > .right {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
          }
          > .chart {
            width: 100%;
            padding-top: 40px;
            > .row {
              margin-top: 20px;
              width: 100%;
              display: flex;
              justify-content: space-around;
              align-content: center;
              > .left,
              > .right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 330px;
                height: 120px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(0, 72, 68, 1);
                text-align: center;
                padding: 0 20px;
                box-sizing: border-box;
              }
              > .left {
                font-size: 28px;
                font-weight: 400;
                line-height: 36px;
                color: rgba(0, 72, 68, 1);
              }
              > .right {
                background: rgba(159, 184, 167, 1);
                border-color: rgba(159, 184, 167, 1);
                font-size: 28px;
                font-weight: 400;
                line-height: 36px;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
      &.course {
        > .content {
          > .t1 {
            padding-top: 40px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            text-align: left;
            color: #666;
          }
          > .t2 {
            padding-top: 20px;
            font-weight: 500;
            font-size: 30px;
            line-height: 50px;
            color: #333;
          }
          > .t3 {
            padding-top: 20px;
            > .item {
              font-weight: 300;
              font-size: 28px;
              line-height: 50px;
              color: #666;
              display: flex;
              justify-content: center;
              align-items: center;
              > .left {
                width: 210px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              > .right {
                padding-left: 20px;
                flex: 1;
                position: relative;
                &:before {
                  content: ' ';
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                  width: 1px;
                  height: 60%;
                  background-color: #666;
                }
              }
            }
          }
          > .subTitle {
            padding-top: 40px;
            font-size: 30px;
            font-weight: 400;
            line-height: 50px;
            color: rgba(0, 0, 0, 1);
          }
          > .chart {
            > .t {
              padding-top: 20px;
              font-size: 28px;
              font-weight: 300;
              line-height: 50px;
              color: rgba(102, 102, 102, 1);
            }
            > .title {
              font-size: 24px;
              font-weight: 300;
              line-height: 50px;
              color: rgba(0, 72, 68, 1);
              text-align: center;
              padding-top: 30px;
            }
            > .img {
              padding-top: 30px;
              margin: auto;
              > img {
                width: 100%;
              }
            }
          }
        }
      }
      &.teacher {
        > .content {
          > .t {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            > .link {
              font-size: 28px;
              font-weight: 400;
              color: rgba(23, 125, 224, 1);
              opacity: 1;
            }
          }
        }
      }
      &.authentication {
        > .content {
          > .t1 {
            padding-top: 30px;
            font-size: 28px;
            line-height: 50px;
            color: #333333;
          }
          > .t2 {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: #666666;
          }
          > .subTitle {
            padding-top: 30px;
            font-size: 28px;
            line-height: 50px;
            color: #333;
          }
        }
      }
    }
  }
`;
