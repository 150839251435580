import React from 'react';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent
      style={{
        backgroundImage: `url('${require('./bg.jpg')}')`
      }}>
      <div className='wrap'>
        <div className='section bonus'>
          <div className='title'>国际高中部奖学金</div>
          <div className='title2'>Scholarships</div>
          <div className='content'>
            <div className='table'>
              <div className='block'>
                <div className='top'>
                  <div className='t1'>入学奖学金</div>
                  <div className='t2 fontEn'>Admission Scholarships</div>
                </div>
                <div className='bottom'>
                  <div className='t'>全额奖学金</div>
                  <div className='t'>三年学费全免</div>
                </div>
                <div className='bottom'>
                  <div className='t'>荣誉奖学金</div>
                  <div className='t'>人民币5000~20000元</div>
                </div>
              </div>
              <div className='block'>
                <div className='top'>
                  <div className='t1'>升学奖学金</div>
                  <div className='t2 fontEn'>
                    Further Education Scholarships
                  </div>
                </div>
                <div className='bottom'>
                  <div className='t'>一等奖学金</div>
                  <div className='t'>人民币50万元</div>
                </div>
                <div className='bottom'>
                  <div className='t'>二等奖学金</div>
                  <div className='t'>人民币10万元</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
