import qs from 'qs';
import Request from '../utils/request';
import storage from '../utils/storage';
import isWx from '../utils/isWx';

const wx = require('weixin-js-sdk');

const isProd = process.env.NODE_ENV === 'production' && isWx;

let shareLoading = false;
let timer: number;

export function share(
  title: string,
  desc: string,
  link: string,
  imgUrl: string,
  success?: () => void
) {
  if (shareLoading) {
    clearTimeout(timer);
    timer = setTimeout(() => share(title, desc, link, imgUrl, success), 500);
    return;
  }
  // 朋友圈
  wx.onMenuShareTimeline({
    title, //  分享标题
    link, //  分享链接
    imgUrl, //  分享图标
    success
  });
  // 分享给朋友
  wx.onMenuShareAppMessage({
    title, //  分享标题
    desc, //  分享描述
    link, //  分享链接
    imgUrl, //  分享图标
    success
  });
  // QQ
  wx.onMenuShareQQ({
    title, //  分享标题
    desc, //  分享描述
    link, //  分享链接
    imgUrl, //  分享图标
    success
  });
  // 腾讯微博
  wx.onMenuShareWeibo({
    title, //  分享标题
    desc, //  分享描述
    link, //  分享链接
    imgUrl, //  分享图标
    success
  });
  // QQ空间
  wx.onMenuShareQZone({
    title, //  分享标题
    desc, //  分享描述
    link, //  分享链接
    imgUrl, //  分享图标
    success
  });
}

const queryObj = qs.parse(window.location.href);
export default async ({
  title = document.title,
  desc = title,
  link = window.location.href,
  imgUrl = '',
  openid = storage.get('openid') || (queryObj.openid as string),
  thirdToken = storage.get('third_token') ||
    (queryObj.third_token as string) ||
    '',
  appId = storage.get('appid') || (queryObj.appid as string),
  isNeedLogin = false
}: {
  title?: string;
  desc?: string;
  link?: string;
  imgUrl?: string;
  openid?: string | null;
  thirdToken?: string | null;
  appId?: string;
  isNeedLogin?: boolean;
} = {}) => {
  if (openid) {
    storage.set('openid', openid);
  }
  if (isNeedLogin && isProd && !thirdToken && !openid) {
    const doLogin = async () => {
      window.document.title = '授权中';
      await Request.post('https://third.parsec.com.cn/api/wxuser/auth', {
        appid: appId,
        [`redirect_uri`]: window.location.href
      }).then(
        ({
          data: {
            result: { url }
          }
        }) => {
          window.document.title = '等待返回';
          window.location.replace(url);
        }
      );
    };
    await doLogin();
  } else if (thirdToken) {
    const title = window.document.title;
    window.document.title = '返回成功';
    storage.set('third_token', thirdToken);
    await Request.post('https://third.parsec.com.cn/api/wxuser/info', {
      [`third_token`]: storage.get('third_token')
    }).then(({ data }) => {
      openid = data.result.openid;
      storage.set('openid', openid + '');
    });
    window.document.title = title;
  }
  let href = window.location.href;
  href = href.replace(`third_token=${thirdToken}`, '');
  href = href.replace(`appid=${appId}`, '');
  href = href.replace(`openid=${openid}`, '');
  if (window.location.href !== href) {
    window.document.title = '等待返回';
    window.location.replace(href);
    return Promise.reject();
  }
  const setWx = () =>
    new Promise<void>(resolve => {
      Request.post('https://depu-api.parsec.com.cn/public/jsconfig', {
        appid: appId,
        url: window.location.href
      }).then(({ data: { result: config } }) => {
        const setConfig = () =>
          wx.config({
            debug: false,
            jsApiList: Object.keys(wx),
            ...config
          });
        setConfig();
        wx.ready(() => {
          shareLoading = false;
          share(title, desc, link, imgUrl);
          resolve();
        });
      });
    });
  await setWx();
  href = window.location.href;
  const restWx = () =>
    setTimeout(() => {
      const { href: newHref } = window.location;
      if (newHref !== href) {
        shareLoading = true;
        href = newHref;
        setWx();
      }
      restWx();
    }, 100);
  restWx();
};
