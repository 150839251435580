import request from '@utils/request';
import createApiHooks from 'create-api-hooks';
import storage from '@utils/storage';

interface ListApiRequestParams {
  limit?: number;
  page?: number;
}
interface ListApiResponseData<D> {
  list: D[];
  total: number;
  pageNum: number;
}

export default {
  getWxUserInfo: createApiHooks(() =>
    request.post('https://third.parsec.com.cn/api/wxuser/info', {
      [`third_token`]: storage.get('third_token')
    })
  ),
  create: createApiHooks(
    (params: {
      studentName: string;
      contactPhone: string;
      schoolAndClass: string;
      department: string;
      channel: string;
    }) => {
      return request.post('/public/appointment', params);
    }
  ),
  resume: createApiHooks(
    (params: {
      truename: string;
      gender: number;
      birthday: string;
      mobilePhone: string;
      email: string;
      city: string;
      graduateFrom: string;
      major: string;
      experience: string;
      skill: string;
      selfEvaluation: string;
    }) => {
      return request.post('/public/recruit', params);
    }
  )
};
