import styled from 'styled-components';
export default styled.div`
  background-color: #f5f5f5;
  padding-bottom: 40px;
  > .gap {
    height: 80px;
    width: 100%;
    background-color: #fff;
  }
  > .jbblock {
    width: 750px;
    background: rgba(159, 184, 167, 1);
    opacity: 1;
    padding: 57px 34px;
    box-sizing: border-box;
    > .title {
      font-size: 50px;
      font-weight: 600;
      line-height: 46px;
      color: rgba(255, 255, 255, 1);
    }
    > .t {
      padding-top: 30px;
      font-size: 28px;
      font-weight: 400;
      line-height: 50px;
      color: rgba(255, 255, 255, 1);
    }
  }
  > .content {
    padding: 0 34px 34px 34px;
    background-color: #fff;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 40px;
      }
      &.aphorism {
        > .content {
          > .t {
            padding-top: 30px;
            font-weight: 300;
            font-size: 28px;
            line-height: 58px;
            color: #666;
          }
        }
      }
      &.jd {
        > .content {
          > .subTitle {
            padding-top: 30px;
            font-size: 30px;
            font-weight: 400;
            line-height: 50px;
            color: rgba(0, 0, 0, 1);
          }
          > .t {
            padding-top: 40px;
            font-weight: 300;
            font-size: 28px;
            line-height: 58px;
            color: #666;
          }
        }
      }
    }
  }
`;
