import styled from 'styled-components';

export default styled.div`
  img {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
  }
`;
