import styled from 'styled-components';
export default styled.div`
  width: 100%;
  height: 482px;
  background-size: cover;
  > .container {
    padding: 72px 32px;
    > .title {
      font-size: 60px;
      font-weight: bold;
      color: rgba(0, 72, 68, 1);
    }
    > .titleEn {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 72, 68, 1);
    }
    > .content {
      padding-top: 30px;
      font-size: 25px;
      line-height: 50px;
      font-weight: 400;
      color: #054547;
      opacity: 0.8;
      font-style: italic;
    }
  }
`;
