import React, { useEffect, useRef, useState } from 'react';
import StyledComponent from './index.style';
import Swiper from 'swiper';
import { animateScroll } from '@src/App';

export default (props: { items: Array<any>; title: string; index: number }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const domRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<any>(null);
  const firstRef = useRef<boolean>(true);
  useEffect(() => {
    const mySwiper = new Swiper(domRef.current!, {
      slidesPerView: 'auto',
      loop: false,
      centeredSlides: true,
      autoHeight: true,
      // initialSlide: 2,
      on: {
        transitionEnd: () => {
          setTimeout(() => {
            if (mySwiper) {
              console.log(mySwiper.activeIndex);
              setActiveIndex(mySwiper.activeIndex);
            }
            if (domRef.current) {
              if (firstRef.current) {
                firstRef.current = false;
                return;
              }
              animateScroll(domRef.current);
            }
          }, 100);
        }
      }
    });
    swiperRef.current = mySwiper;
    return () => {
      swiperRef.current!.destroy(true, true);
    };
  }, []);
  return (
    <StyledComponent data-index={props.index}>
      <div className='title'>
        <div className='line' />
        <div className='text'>{props.title}</div>
        <div className='line' />
      </div>
      <div className='swiper-container' ref={domRef}>
        <div className='swiper-wrapper'>
          {props.items.map((item, index) => {
            return (
              <div key={index} className='swiper-slide'>
                {item}
              </div>
            );
          })}
        </div>
        {props.items && props.items.length > 1 ? (
          <div className='page'>
            <div
              className='btn prev'
              onClick={() => {
                swiperRef.current!.slidePrev();
              }}>
              <img src={require('./icon_prev.png')} alt='prev' />
            </div>
            <div className='progress'>
              {activeIndex + 1}/{props.items.length}
            </div>
            <div
              className='btn next'
              onClick={() => {
                swiperRef.current!.slideNext();
              }}>
              <img src={require('./icon_next.png')} alt='next' />
            </div>
          </div>
        ) : (
          undefined
        )}
      </div>
    </StyledComponent>
  );
};
