export default [];

export const CourseTable = {
  ALevelCourses: {
    sub: 'A-Level Courses',
    list: [
      'A-Level Chinese',
      'A-Level Mathematics',
      'A-Level Further Mathematics',
      'A-Level Physics',
      'A-Level Chemistry',
      'A-Level Biology',
      'A-Level Computer Science',
      'A-Level Economics',
      'A-Level Accounting',
      'A-Level Sociology',
      'A-Level Art and Design',
      ' '
    ]
  },
  APCourses: {
    sub: 'AP Courses',
    list: [
      'AP Calculus AB',
      'AP Calculus BC',
      'AP Statistics',
      'AP Macroeconomics',
      'AP Microeconomics',
      'AP Physics C: Electricity and Magnetism',
      'AP Physics C: Mechanics',
      'AP Chemistry',
      ' ',
      ' ',
      ' ',
      ' '
    ]
  },
  CHHSC: {
    sub: 'Chinese High School Courses',
    list: [
      'Chinese',
      'Mathematics',
      'English',
      'Physics',
      'Chemistry',
      'Biology',
      'Geography',
      'History',
      'Politics',
      'Information Technology (I.T.)',
      'General Technology (G.T.)',
      'Physical Education (P.E.)'
    ]
  }
};

export const HonorsCurriculum = {
  type: {
    name: 'Curriculum Type',
    content1: 'David Club Curriculum'
  },
  desc: {
    name: 'Curriculum Description',
    content1:
      'David Club Curriculum is intended for excellent students who reach certain academic criteria. It consists of one-on-one major-oriented competence training courses, interview skills training courses, and thinking expansion training courses.The curriculum covers a wide range of academic areas, containing mathematics, physics, biology, chemistry, engineering, social science, economics/business, computer science, and art, some of which are taught by experts from the University of Oxford and the University of Cambridge.'
  }
  // apply: {
  //   name: 'Applicable To',
  //   content1: 'Grade 11 students with excellent performance in every subject'
  // }
};

export const ProfiletTable = {
  DepuSchool: {
    sub: 'DEPU School',
    list: [
      {
        name: 'Student population: ',
        num: '2038'
      },
      {
        name: 'International Students: ',
        num: '5%'
      },
      {
        name: '(from 15 countries and regions)',
        longtext: true
      },
      {
        name: 'Student-teacher ratio: ',
        num: '4:1'
      },
      {
        name: 'Class size: ',
        num: '≦30'
      },
      {
        numleft: '130+',
        name: ' Elective courses'
      },
      {
        numleft: '100+',
        name: ' Student societies'
      }
    ]
  },
  ItnlSeniorHightSchool: {
    sub: 'International Senior High School',
    list: [
      {
        name: '24 IGCSE+A-Level Courses'
      },
      {
        name: 'Student-teacher ratio ',
        num: '5:1'
      },
      {
        name: 'Class size: ',
        num: '≦26'
      },
      {
        name: 'Total enrollment……………',
        num: '398'
      }
      // {
      //   name: 'Class of 2023…………………',
      //   num: '115'
      // },
      // {
      //   name: 'Class of 2024…………………',
      //   num: '128'
      // },
      // {
      //   name: 'Class of 2025…………………',
      //   num: '155'
      // },
      // {
      //   name: 'College acceptance rate: ',
      //   num: '100%'
      // }
    ]
  }
};

export const activities = {
  ArtandSports: {
    title: 'Art and Sports:',
    list: [
      'DEPU“Half Marathon”',
      'Orienteering',
      'Gobi Hiking',
      'Drama',
      'Cool Summer Music Festival ',
      'Winter Evening Gala',
      'Art & Design Exhibition'
    ]
  },
  Academics: {
    title: 'Academics:',
    list: [
      'DEPU iBusiness Challenge',
      'Debate Contest',
      'Chemistry Day',
      'Mathematicians',
      'Formula Design Competition',
      'Bio Discovery',
      'Science Fair'
    ]
  },
  StudentClubs: {
    title: 'Student Clubs:',
    list: [
      'DEPU Autonomous Committee',
      'AIR STAR Drone Club',
      'Blossom Biology Club',
      'Economics Super Bowl Club',
      'Blockbuster Debate Club',
      'P.Y.C.M.(Passion,Youth,Curiosity,Miracle)'
    ]
  },
  CareerPlanning: {
    title: 'Career Planning:',
    list: [
      'University Exhibition',
      'Career Workshop',
      'Mock Job Fair',
      ' ',
      ' ',
      ' '
    ]
  }
};

export const SchoolList = [
  'American Musical and Dramatic Academy',
  'Art Center College of Design',
  'Australian National University',
  'Durham University',
  'Imperial College London',
  'King’s College London',
  'Leiden University',
  'McGill University',
  'Nanyang Technological University,Singapore',
  'Pratt Institute',
  'Purdue University',
  'Savannah College of Art and Design',
  'School of the Art Institute of Chicago',
  'School of Visual Arts',
  'The University of Hong Kong',
  'The University of Manchester',
  'The University of Melbourne',
  'The University of New South Wales',
  'The University of Sydney',
  'The University of Warwick',
  'Trinity College Dublin, The University of Dublin',
  'University of Malaya',
  'University College London',
  ' University of Amsterdam',
  'University of British Columbia',
  'University of California, Davis',
  'University of Groningen',
  'University of Oxford'
];
