import React from 'react';
import StyledComponent from './index.style';
export default (props: {
  img: string;
  title: string;
  titleEn: string;
  content: JSX.Element;
}) => {
  return (
    <StyledComponent
      style={{
        backgroundImage: `url('${props.img}')`
      }}>
      <div className='container'>
        <div className='title'>{props.title}</div>
        <div className='titleEn fontEn'>{props.titleEn}</div>
        <div className='content'>{props.content}</div>
      </div>
    </StyledComponent>
  );
};
