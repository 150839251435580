import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 48px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
      }
      &.graduate {
        > .content {
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
            padding-top: 30px;
          }
        }
      }
    }
  }
`;
