import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
        > .mini {
          font-size: 34px;
        }
      }
      &.graduate {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
          > .title {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(0, 72, 68, 1);
            text-align: center;
          }
          > .table {
            font-size: 22px;
            font-weight: 300;
            line-height: 16px;
            color: rgba(102, 102, 102, 1);
            border: none;
            > .row {
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: 1px solid rgba(159, 184, 167, 1);
              > .col {
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-right: 1px solid rgba(159, 184, 167, 1);
                &:nth-child(1) {
                  border-left: 1px solid rgba(159, 184, 167, 1);
                  width: 350px;
                }
                &:nth-child(2) {
                  width: 300px;
                  padding-left: 30px;
                  text-align: left;
                }
                &:nth-child(3) {
                  width: 183px;
                }
                &:nth-child(4) {
                  width: 127px;
                }
              }
              &:nth-child(1) {
                border-bottom-width: 0;
                border-top-width: 0;
                background-color: rgba(159, 184, 167, 1);
                > .col {
                  border-right-color: rgba(255, 255, 255, 1);
                  color: #fff;
                  &:last-child {
                    border-right-color: rgba(159, 184, 167, 1);
                  }
                }
              }
            }
          }
        }
      }
      &.course {
        > .content {
          > .t1 {
            padding-top: 40px;
            font-weight: 300;
            font-size: 28px;
            line-height: 50px;
            text-align: left;
            color: #666;
          }
          > .t2 {
            padding-top: 20px;
            font-weight: 500;
            font-size: 30px;
            line-height: 50px;
            color: #333;
          }
          > .t3 {
            padding-top: 20px;
            > .item {
              font-weight: 300;
              font-size: 28px;
              line-height: 50px;
              color: #666;
              display: flex;
              justify-content: center;
              align-items: center;
              > .left {
                width: 190px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              > .right {
                padding-left: 20px;
                flex: 1;
                position: relative;
                &:before {
                  content: ' ';
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                  width: 1px;
                  height: 60%;
                  background-color: #666;
                }
              }
            }
          }
        }
      }
      &.level {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .title {
            padding-top: 20px;
            font-size: 28px;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .list {
            > .item {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              margin-top: 30px;
              > img {
                padding-top: 15px;
                width: 26px;
              }
              > div {
                padding-left: 15px;
                flex: 1;
                font-size: 28px;
                font-weight: 300;
                line-height: 50px;
                color: rgba(102, 102, 102, 1);
              }
            }
          }
        }
      }
      &.cq {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .title {
            padding-top: 30px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(0, 72, 68, 1);
            text-align: center;
          }
          > .chart {
            padding-top: 50px;
            padding-bottom: 20px;
            width: 100%;
            > img {
              width: 100%;
            }
          }
        }
      }
      &.teacher {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .link {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 400;
            line-height: 40px;
            color: rgba(23, 125, 224, 1);
          }
        }
      }
      &.bonus {
        > .content {
          > .t {
            padding-top: 40px;
            padding-bottom: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .table {
            border: 2px solid rgba(159, 184, 167, 1);
            > .block {
              text-align: center;
              > .top {
                padding: 20px 0;
                font-weight: 400;
                line-height: 60px;
                color: rgba(0, 72, 68, 1);
                > .t1 {
                  font-size: 40px;
                }
                > .t2 {
                  font-size: 30px;
                }
              }
              > .bottom {
                padding: 20px 0;
                background-color: #9fb8a7;
                &:last-child {
                  border-top: 1px dashed rgba(255, 255, 255, 0.4);
                }
                > .t {
                  font-size: 30px;
                  font-weight: 400;
                  color: rgba(255, 255, 255, 1);
                  line-height: 60px;
                }
              }
            }
          }
        }
      }
      &.authentication {
        > .content {
          > .subTitle {
            padding-top: 40px;
            font-size: 28px;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
`;
