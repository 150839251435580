import styled from 'styled-components';
export default styled.div`
  width: 750px;
  height: 482px;
  background-size: cover;
  overflow: hidden;
  position: relative;
  > .container {
    width: 100%;
    height: 100%;
    over-flow: hidden;
    > .title {
      margin-left: 34px;
      margin-top: 80px;
      font-size: 58px;
      font-family: PingFang HK;
      font-weight: 600;
      line-height: 62px;
      color: #004844;
      -webkit-text-stroke: 1 #004844;
      text-stroke: 1 #004844;
      opacity: 1;
    }
    > .titleEn {
    }
    > .content {
      position: absolute;
      right: 15px;
      bottom: 22px;
      margin-left: 34px;
      margin-top: 12px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 350;
      line-height: 35px;
      color: #004844;
      opacity: 1;
    }
  }
`;
