import styled from 'styled-components';
export default styled.div`
  .title {
    font-size: 48px;
    font-family: PingFang SC;
    font-weight: 550;
    line-height: 46px;
    color: #004844;
    -webkit-text-stroke: 0 #004844;
    text-stroke: 0 #004844;
    opacity: 1;
  }
  .text_item {
    position: relative;
    word-break: normal;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 36px;
    color: #666666;
    padding-left: 15px;
    position: relative;
    margin-bottom: 30px;
    .item.gou {
      ::before {
        content: '✔';
        font-size: 20px;
        opacity: 0.95;
        color: #004844;
        padding-right: 10px;
        position: absolute;
        left: -10px;
      }
    }
  }
  .common_item {
    font-family: PingFang SC;
    padding-left: 15px;
    position: relative;
    margin-bottom: 30px;
  }

  .common_item::before {
    content: '●';
    font-size: 20px;
    opacity: 0.95;
    color: #004844;
    padding-right: 10px;
    position: absolute;
    left: -10px;
  }
  .section {
    margin-bottom: 95px;
    .title {
      margin-bottom: 10px;
    }
  }
  .table {
    width: 100%;
  }
  ._content {
    font-family: PingFang SC;
    margin-top: 102px;
    padding: 0 34px;
    .title {
      min-height: 70px;
      line-height: 60px;
    }
  }
  .profile {
    .table {
      display: flex;
      justify-content: space-between;
      flex-wrap: no-wrap;
      color: #ffffff;
      .tb {
        width: 335px;
        height: 460px;
        background: rgba(159, 184, 167);
        opacity: 1;
        padding: 22px 0 22px 8px;
        box-sizing: border-box;
        .sub {
          height: 82px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 40px;
          margin-botttom: 35px;
        }
        .item {
          font-size: 20px;
          line-height: 40px;
          white-space: nowrap;
          font-weight: 300;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
  .core {
    .core_item {
      span {
        color: #333;
        font-weight: 400;
        padding-right: 6px;
      }
    }
  }
  .grading {
    .special {
      font-size: 16px;
      margin-bottom: 30px;
      color: #666666;
      &::before {
        content: '※';
        color: #004844;
        font-size: 12px;
        padding-right: 10px;
        display: inline-block;
        transform-origin: left;
        padding-left: 2%;
        transform: scale(70%);
      }
    }
    .text_item {
      margin-bottom: 14px;
    }
    .item.gou {
      line-height: 28px;
    }
    .common_item {
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
  .test_scores {
    .chart {
      font-weight: 400;
    }
    .note {
    }
  }
  .activities {
    .table {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .tb {
        width: 335px;
        margin-bottom: 12px;
        > div.item {
          height: 50px;
          line-height: 50px;
          font-size: 15px;
          padding-left: 6px;
          border: 1px solid #9fb8a7;
          border-bottom: 0;
          white-space: nowrap;
          &:last-child {
            border-bottom: 1px solid #9fb8a7;
          }
        }
        .sub {
          background: rgba(159, 184, 167);
          font-size: 20px;
          color: #ffffff;
          padding-left: 6px;
          line-height: 50px;
        }
        &.three {
          div:last-child {
            position: relative;
            span {
              position: absolute;
              display: block;
              transform-origin: left;
            }
          }
        }
      }
    }
  }

  .curriculum {
    .text_item {
      margin-bottom: 30px;
    }
    .common_item {
      margin-bottom: 14px;
      color: #333333;
      font-weight: 400;
    }
    .inner {
      font-family: PingFang SC;
      padding-left: 10px;
    }
    .table1 {
      margin-bottom: 68px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: no-wrap;
      .tb {
        > div {
          position: relative;
          color: #666666;
          font-size: 15px;
          padding-left: 3px;
          border: 1px solid #9fb8a7;
          border-bottom: 0;
          border-right: 0;
          white-space: nowrap;
          height: 38px;
          line-height: 38px;
          &:last-child {
            border-bottom: 1px solid #9fb8a7;
          }
          &.double {
            height: 76px;
          }
          > span {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            transform-origin: left;
            padding-left: 2%;
            transform: scale(80%);
          }
        }
        &.one {
          width: 225px;
          .long {
            position: relative;
            min-height: 38px;
            span {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              transform-origin: left;
              padding-left: 2%;
              transform: scale(65%);
            }
          }
        }
        &.two {
          width: 255px;
          .long {
            position: relative;
            span {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              transform-origin: left;
              padding-left: 2%;
            }
          }
        }
        &.three {
          width: 204px;
          .long {
            position: relative;
            span {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              transform-origin: left;
              padding-left: 2%;
              transform: scale(65%);
            }
          }
          > div {
            border-right: 1px solid #9fb8a7;
          }
          .sub {
            text-align: left;
            line-height: 32px;
            white-space: normal;
          }
        }
        .sub {
          background: rgba(159, 184, 167);
          height: 65px;
          line-height: 65px;
          text-align: left;
          color: #ffffff;
          border-right: 1px solid #ffffff;
          font-size: 16px;
        }
      }
    }
    .table2 {
      display: flex;
      justify-content: space-between;
      flex-wrap: no-wrap;
      margin-bottom: 31px;
      .item {
        border: 1px solid #9fb8a7;
      }
      .sub {
        font-size: 19px;
        height: 65px;
        line-height: 65px;
        color: #fff;
        background: rgba(159, 184, 167);
        padding-left: 10px;
      }
      .content {
        height: 421px;
        line-height: 27px;
        display: flex;
        justify-conten: center;
        align-items: center;
        span {
          display: block;
          transform-origin: left;
          padding-left: 2%;
          transform: scale(95%);
          text-align: left;
          line-height: 35px;
        }
      }

      .type {
        width: 35%;
        .sub {
          border-right: 0;
          color: #ffffff;
          border-right: 1px solid #ffffff;
          font-size: 19px;
        }
        .content {
          vertical-align: middle;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          line-height: 24px;
          color: #666666;
          border-right: 0;
          display: flex;
          align-items: center;
        }
      }

      .desc {
        width: 65%;
        .sub {
          border: 1px solid #9fb8a7;
        }

        .content {
          text-align: left;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #666666;
        }
      }
    }
    .remark {
      .sub {
        color: #004844;
      }
      .text_item {
        padding: 19px;
        padding-top: 0;
        line-height: 38px;
        font-size: 17px;
        color: #666;
      }
    }
    .item.feature {
      font-size: 27px;
      line-height: 39px;
    }
  }
  .graduates {
    .school_list {
      font-size: 24px;
      background: rgba(159, 184, 167, 0.39);
      padding: 42px 32px;
      line-height: 50px;
      margin-bottom: 100px;
      color: #004844;
    }
  }
  .administrators {
    .info {
      margin-bottom: 22px;
      .name {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 5px;
      }
      .email {
        word-break: normal;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 300;
        line-height: 36px;
        color: #666666;
      }
    }
    .title-small {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 550;
      line-height: 46px;
      color: #004844;
      -webkit-text-stroke: 0 #004844;
      text-stroke: 0 #004844;
      opacity: 1;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`;
