import React from 'react';
import LazyImg from '@components/LazyImg';

export const xbczStr = `
01吕俊松
Lv Junsong
小班初中部语文教师
Chinese Teacher of Smart Junior Department
中学高级教师
Senior Teacher of Middle School
市级优秀教师
Municipal Excellent Teacher
从教32年，有丰富的中考备考经验
32 years of teaching experience and rich experience in preparation for senior high school entrance examination 
原湖南新田德恒实验学校常务副校长
Former Deputy Executive Principal of Xintian Deheng Experimental School, Hunan Province
idea
备好每堂课，上好每堂课；用知识充实学生，用灵魂感染学生。
Educational concept: Prepare and teach each lesson well; Enrich students with knowledge and influence students with my spirit.
--
02贺晓明
He Xiaoming
中学历史高级教师
Senior History Teacher of Middle School
重庆市巴南区骨干教师
The Cadre Teacher of Banan District, Chongqing
重庆市优质课大赛一等奖
First Prize Winner in Chongqing High Quality Course Competition
原湛江市湛江一中培才学校骨干教师
Former Cadre Teacher in Zhanjiang Yizhong Peicai School
原深圳龙岗初级中学历史教师和文科综合科科组长，学生成长导师，并连续两次荣获“优秀班主任”称号
Former History Teacher and Team Leader of Liberal Art Synthesis Department in Shenzhen Longgang Primary School, Students Growth Tutor, Twice Winner of the Title of “Excellent Class Teacher”
连续4年荣获“优秀教师”、“优秀班主任”、“优秀党员”
Awardee of “Excellent Teacher”, “Excellent Homeroom Teacher” and “Excellent Party Member” for 4 consecutive years
idea
爱是最好的教育，而表达爱最好的方法就是鼓励和赞赏。
Educational concept: Love is the best education, and the best way to express love is encouragement and admiration.


--
05茹妮妮
Ru Nini
小班初中部数学教师
Mathematics Teacher
重庆中考数学命题人
Chongqing High School Entrance Examination Mathematics Proposition Person
多次荣获“一师一优”国家级部优
Awardee of the National Ministerial “One Division and One Excellence” for many times
主持重庆市市级课题
Presided over Chongqing Municipal Project
巴南区优质课赛课一等奖
First Prize Winner in the District-wide Teaching Competition 
首届数学生态阅读教学一等奖
First Prize Winner in the First Mathematics Ecological Reading Teaching
巴渝文化的编者
Editor of Bayu Culture
idea
教育是点燃求知的火焰，唤醒求知的思想，教学相长，一起成为更好的自己。
Educational concept: Education is to ignite the flame of knowledge seeking, awaken the thought of knowledge seeking, teach and learn together, and become better ourselves.


--
07刘正远
Liu Zhengyuan
小班初中英语教师
English Teacher
中学一级教师,原重庆外国语学校英语教师、工会组长、年级主任助理
Class teacher, English teacher, assistant of grade director or Chongqing Foreign Language School.
从教9年，班主任工作6年，重庆外国语学校优秀班主任、优秀团干部，四川外国语大学优秀工会组长、多次被评为备课组先进个人。
Having been an English teacher for 9 years and a head teacher for 6 years. He was awarded "Excellent Teacher"by Jiu Longpo Educational Committee and “Excellent Teacher", “Excellent Head Teacher” of Chongqing Foreign Language School.
注重培养有利于学生终生发展的学习习惯、综合素质以及自我管理的意识和能力。
He has focused on cultivating students’ abilities of study habit, comprehensive abilities as well as their self-management abilities which are critical for their lifelong development.
idea
用心灵赢得心灵,以人格塑造人格
Educational concept: Heart is won by self, personality is molded by self either.

--
08熊欣
Xiong Xin
小班初中英语教师
English Teacher
重庆英语优质课大赛一等奖第一名
First Prize Winner of The English Language Teaching Competition in Chongqing 
中考英语命题人
Paper Setter of High School Entrance Examination 
香港城市大学翻译传译硕士
Master of Translation and Interpretation, City University of Hong Kong
原香港Lutheran Academy优秀教师
Former Excellent Teacher in Hong Kong Lutheran Academy
在泰国北部乌隆市进行英文教学
English Teacher in Udon Thani, Northern Thailand
idea
相互尊重是和谐师生关系的前提，每个学生都有属于他自己的闪光点，老师要做的是尽量去发现学生的闪光点。
Educational concept:Mutual respect is the premise of harmonious teacher-student relationship. What a teacher should do is to do his/her best to discover the shining point of each student.

--
09王端
WangDuan
小班初中历史老师
History teacher
中学一级教师
First Level Teacher of the Secondary School
重庆市历史优质课大赛一等奖
First Prize Winner in Chongqing History High Quality Course Competition
重庆市历史优质课大赛指导教师一等奖
First Prize Winner in Instructing Chongqing High Quality Course Competition
参与教育部“一师一优课”部级优课
Participant in Ministry Level Activity “One Division and One Excellence”
《研学巴渝》编委
Editorial Board of Research the Bayu
重庆市中小学德育精品课一等奖
First Prize Winner of Excellent Moral Education Course in Primary and Secondary Schools
多次获得市区级论文一、二等奖
Won the First and Second Prizes of Excellent Papers at District Level for many times
数次获得优秀教师、优秀班主任
Awardee of  “Excellent Teacher”, “Excellent Class Teacher” for many times 
重庆市家庭教育指导师
Chongqing Family Education Instructor
idea
师者，所以传道授业解惑也。
Educational concept: The teacher is the one who could propagate the doctrine, impart professional knowledge, and resolve doubts.

--
10陈瑶
Chen Yao
小班初中道德与法治教师
Moral and Legal teacher
中学一级政治教师
First Level Political Teacher of the Secondary School
重庆市骨干教师
The Cadre Teacher of Chongqing
重庆市初中道德与法治优质课大赛一等奖
First Prize Winner in Chongqing Political High Quality Course Competition
重庆市政治学科命题技能大赛一等奖
First Prize Winner in Chongqing Political Propositional skills Competition
重庆市德育精品课程一等奖
First Prize Winner of Excellent Moral Education Course in Primary and Secondary Schools
巴南区思政教师专家库成员
Member of Banan District Ideological and Political Expert Group
“一师一优课”活动国家部级优课“一等奖”
First Prize Winner in Ministry Level Activity “one division and one excellence”
重庆市易家志名班主任工作室成员
Member of Chongqing Yijiazhiming Class Teacher Studio
多次被校级评为“优秀教师”、“优秀班主任”、“最受欢迎老师”
Awardee of “Excellent Teacher”, “Excellent Homeroom Teacher”, “Most popular teacher” for many times 
idea
与每一个孩子共成长，以心换心，用爱去陪伴每一个孩子的成长与超越！
Educational concept: Teachers accompany children to grow together with sincerely love!

--
12David C. Shave


English Teacher of Smart Junior Department

Bachelor of Science, Psychology - University of Stirling

10 years of teaching experience in China, with Owen Education & TAL First Leap

Educational concept: Teaching is the job of inspiring students to find their place in the world around them, to understand that world, and to equip them with the tools they need to succeed in the future they are creating for themselves.

--
13Eric Matthews


English Teacher of Smart Junior Department

Bachelor of History (1st Class Degree) - the University of Essex

Certificate of Higher Education in Chemistry from the University of East Anglia

4 years teaching at Chongqing Foreign Language School

Certificated with the TKT Module 1

Short-term English teaching in Romania

Educational concept: Help the students to want to learn, foster their growth and then they can reach their potential. A student is a human being, not a test taking machine. Finding their own path and their own weaknesses and strengths is the key to a good education, and making a well rounded human.

`;
const dataFilter = (str: string, images: string[]) => {
  const blocks = str.split('--').map(x => x.replace(x[0], ''));
  return blocks.map((block, index) => {
    const rows = block.split('\n');
    const name = rows[0].replace(/\d+/, '');
    const nameEn = rows[1];
    const desc: string[] = [];
    const descEn: string[] = [nameEn];
    let i = 2;
    for (; i < rows.length; i++) {
      const row = rows[i];
      if (row === 'idea') {
        break;
      }
      if (i % 2 === 0) {
        desc.push(row);
      } else {
        descEn.push(row);
      }
    }
    const idea = rows[i + 1];
    const ideaEn = rows[i + 2];
    if (ideaEn) {
      descEn.push(ideaEn);
    }
    const isNotEmpty = desc.some(x => !!x);
    return (
      <>
        <div className='photo'>
          <LazyImg src={images[index]} alt='' />
        </div>
        <div className='name'>
          <div className='text'>{name}</div>
        </div>

        {isNotEmpty && (
          <div className='desc'>
            {desc.map((x, index) => {
              return x ? (
                <div key={index}>{x}</div>
              ) : (
                <div key={index}>&nbsp;</div>
              );
            })}
          </div>
        )}
        {idea ? <div className='idea'>教育理念：{idea}</div> : undefined}
        {/* {ideaEn ? <div className='idea'>{ideaEn}</div> : undefined} */}
        <div className='descEn'>
          {descEn.map((x, index) => {
            return x ? (
              <div key={index}>{x}</div>
            ) : (
              <div key={index}>&nbsp;</div>
            );
          })}
        </div>
        <div className='gap' />
      </>
    );
  });
};
export default dataFilter(xbczStr, [
  require('./XbczImage/01吕俊松.png'),
  require('./XbczImage/02贺晓明.jpg'),
  require('./XbczImage/05茹妮妮.jpg'),
  require('./XbczImage/08刘正远.png'),
  require('./XbczImage/09熊欣.jpg'),
  require('./XbczImage/10王端.png'),
  require('./XbczImage/11陈瑶.png'),
  require('./XbczImage/13David.png'),
  require('./XbczImage/14Eric.png')
]);
