import React from 'react';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent>
      <div className='introduction'>
        <div className='title'>
          <div className='line' />
          <div className='text'>学校简介</div>
          <div className='line' />
        </div>

        <div className='each'>
          <div className='content'>
            重庆德普外国语学校是一所集小学、初中、高中为一体的十二年一贯制国际化学校，学校于2015年开学，开设了双语寄宿小学、小班初中、双语初中、国际高中四个学部。德普外国语学校位于重庆市巴南区，占地面积98.7亩。
            <br /> <br />
            学校以“修之于天下，其德乃普”为校训，致力于将“面向快速变化、充满不确定性的未来，深刻理解家长焦虑，回归教育本质，创新教育和管理，打造真正中西融合的12年一贯制课程体系，培养健康快乐、懂中国懂世界的创变者。”
            <br /> <br />
            2019年，重庆德普外国语学校被评选为新学说“2019中国新锐国际学校”。2020年开始，德普外国语学校入选“胡润百学·中国国际学校百强”榜单；2021年和2022年，学校继续蝉联“胡润百学·中国国际学校百强”榜单，分别位列全国第43位、41位。
            <br /> <br />
            2024年4月正式成立“中国四校联盟”，联盟成员为：北京市海嘉学校、重庆德普外国语学校、东莞市松山湖清澜山学校、上海青浦区协和双语学校。首届会议暨签约仪式在重庆德普外国语学校举办。
          </div>
        </div>

        <div className='each'>
          <ul>
            <li>
              <span className='name'>中文名</span>
              <span className='descript'>重庆德普外国语学校</span>
            </li>
            <li>
              <span className='name'>英文名</span>
              <span className='descript'>
                Chongqing DEPU Foreign Language School
              </span>
            </li>
            <li>
              <span className='name'>简称</span>
              <span className='descript'>德普，DEPU</span>
            </li>
            <li>
              <span className='name'>创办时间</span>
              <span className='descript'>2015年</span>
            </li>
            <li>
              <span className='name'>办学性质</span>
              <span className='descript'>小学、初中、高中</span>
            </li>
            <li>
              <span className='name'>学校类型</span>
              <span className='descript'>
                十二年一贯制，寄宿制，民办国际化学校
              </span>
            </li>
            <li>
              <span className='name'>所属地区</span>
              <span className='descript'>中国·重庆</span>
            </li>
            <li>
              <span className='name'>现任校长</span>
              <span className='descript'>许凌可</span>
            </li>
            <li>
              <span className='name'>校训</span>
              <span className='descript'>修之于天下，其德乃普</span>
            </li>
            <li>
              <span className='name'>培养目标</span>
              <span className='descript'>培养懂中国懂世界的创变者</span>
            </li>
            <li>
              <span className='name'>占地</span>
              <span className='descript'>98.7亩</span>
            </li>
            <li>
              <span className='name'>建筑面积</span>
              <span className='descript'>12.5万平方米</span>
            </li>
            <br />
            <li>
              <span className='name'>主要荣誉</span>
              <span className='descript'></span>
            </li>
            <li className='gap'>
              <span className='descript'>2019中国新锐国际学校</span>
            </li>
            <li className='gap'>
              <span className='descript'>
                2019京领中国国际学校竞争力排名英国方向第55名
              </span>
            </li>
            <li className='gap'>
              <span className='descript'>2020胡润百学·中国百强国际学校</span>
            </li>
            <li className='gap'>
              <span className='descript'>
                2021胡润百学·中国国际学校百强第43名
              </span>
            </li>
            <li className='gap'>
              <span className='descript'>
                2022胡润百学·中国国际学校百强第41名
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className='history'>
        <div className='title'>
          <div className='line' />
          <div className='text'>历史沿革</div>
          <div className='line' />
        </div>

        <div className='each'>
          <div className='content'>
            2024年4月，四校联盟首届会议暨签约仪式在德普举行。至此，德普与北京市海嘉学校、东莞市松山湖清澜山学校、上海青浦区协和双语学校正式成为一个紧密相连的教育共同体。
            <br /> <br />
            2023年9月，德普通过Cognia认证，且为小学、初中、高中的一体化认证。这意味着，德普在各个方面都达到了全球教育发展趋势和挑战的衡量标准。
            <br /> <br />
            10月，《德普学生核心素养》正式发布，它包含了两大文化基础，三种思维方式，四个关键能力，三大品格修养。
            <br /> <br />
            2022年，德普继续蝉联“胡润百学·中国国际学校百强”榜，位列全国第41位。
            <br /> <br />
            2021年3月，德普校歌《不问西东》在开学典礼上正式发布。4月，胡润百学发布《2021胡润百学·中国国际学校百强》榜单，德普位列全国第43位。
            <br /> <br />
            2020年5月，德普上榜“胡润百学·中国百强国际学校”。
            <br /> <br />
            2020年9月，德普二期校园通过来自多方的“面试”，小学部的孩子们搬进了“新家”。12月，德普与罗浮山国学院附属学校结为国学教育共同体，德普成为“曾仕强教授国学教育共建学校”。
            <br /> <br />
            2019年4月，剑桥大学国际考评部东亚区学校大会在德普举行，来自东亚地区140多所剑桥国际学校的270多名教育从业者齐聚德普，共同探讨国际教育的新格局。
            <br /> <br />
            同年，德普荣获由新学说颁发的“2019中国新锐国际学校”。
            <br /> <br />
            2018年10月，剑桥大学国际考评部正式批准德普成立剑桥国际教师职业发展中心(简称PDQ中心)，这是西南地区第一所PDQ中心。
            <br /> <br />
            2017年，德普妈妈合唱团、德普话剧社、德普美式橄榄球队、马术俱乐部相继成立。
            <br /> <br />
            2016年11月，德普举办主题为“一衍万物”的一周年校庆活动，监事会主席刘晓棕先生携夫人出席并做现场演讲。
            <br /> <br />
            2016年5月，德普首届高中毕业生全部被全球排名TOP40的大学录取，其中包括帝国理工学院、伦敦大学学院、伦敦国王学院、澳洲国立大学、爱丁堡大学等。
            <br /> <br />
            2015年9月，德普迎来建校后第一批新生211人。
            <br /> <br />
            2014年5月，重庆德普外国语学校在重庆市巴南区龙洲湾举行开工奠基典礼，德普教育监事会主席刘晓棕先生为德普破土动工。
          </div>
        </div>
      </div>

      <div className='conditions'>
        <div className='title'>
          <div className='line' />
          <div className='text'>办学条件</div>
          <div className='line' />
        </div>
        <div className='each'>
          <div className='sub-title'>办学规模</div>
          <div className='content'>
            <em>据2022年官网发布的数据显示，校园占地98.7亩，</em>
            建筑面积112000m²，
            <em>分中学部、小学部，在校学生2000余人，教职工500余人。</em>
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>基础设施</div>
          <div className='content'>
            <em>学校有NBA规格的篮球馆、壁球馆、击剑馆、射击馆、攀岩墙、</em>
            <em>
              迷你高尔夫室、24小时恒温游泳馆、专业形体室、舞蹈房、钢琴房......
            </em>
            <em>
              所有的硬件设施都是为了更好地服务于课程，让课程能够完整落地。
            </em>
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>师资力量</div>
          <div className='content'>
            <em>
              截至2023年初，学校有若干高级教师、骨干教师、优秀班主任，也有毕业于世界名校的优秀老师等。他们成为学生走向更广阔世界的领路人（此处仅为部分）。
            </em>
          </div>
          <ul>
            <li>
              <span className='name gaoji'>高级教师</span>
              <span className='descript'>吕俊松、肖红静、贺晓明、杨蝶等</span>
            </li>
            <li>
              <span className='name'>付颖</span>
              <span className='descript'>
                硕士研究生、英国伦敦大学学院对外英语专业
              </span>
            </li>
            <li>
              <span className='name'>王丹</span>
              <span className='descript'>硕士研究生、剑桥大学</span>
            </li>
            <li>
              <span className='name'>胡瑾</span>
              <span className='descript'>
                硕士研究生、新加坡国立大学应用经济学专业
              </span>
            </li>
            <li>
              <div className='name'>张慧婷</div>
              <span className='descript'>
                硕士研究生、英国爱丁堡大学对外英语教学专业
              </span>
            </li>
            <li>
              <span className='name'>雷小寒</span>
              <span className='descript'>
                硕士研究生、英国曼彻斯特大学运筹学与风险分析专业
              </span>
            </li>
            <li>
              <span className='name'>熊欣</span>
              <span className='descript'>
                硕士研究生、中国香港城市大学翻译传译专业
              </span>
            </li>

            <li>
              <span className='name'>张立妮</span>
              <span className='descript'>
                硕士研究生、澳大利亚麦考瑞大学education&work专业
              </span>
            </li>
            <li>
              <span className='name'>邓澄</span>
              <span className='descript'>
                硕士研究生、澳大利亚麦考瑞大学会计学专业
              </span>
            </li>
            <li>
              <span className='name'>田雯霞</span>
              <span className='descript'>美国加州州立大学-金融&会计双学位</span>
            </li>
            <li>
              <span className='name'>汪国平</span>
              <span className='descript'>
                博士在读、重庆大学概率论与数理统计硕士
              </span>
            </li>
            <li>
              <span className='name'>赵桂云</span>
              <span className='descript'>硕士研究生、浙江大学遗传学专业</span>
            </li>
            <li>
              <span className='name'>张富丽</span>
              <span className='descript'>
                硕士研究生、首都师范大学历史学专业
              </span>
            </li>
            <li>
              <span className='name'>贾凌</span>
              <span className='descript'>
                硕士研究生、华中师范大学语言学及应用语言学专业
              </span>
            </li>
            <li>
              <span className='and-so-on'>......</span>
            </li>
          </ul>
        </div>

        <div className='each'>
          <div className='sub-title'>办学特色</div>
          <div className='content'>
            <span className='summary colon'>十二年一贯制</span>
            <span className='detail'>
              从小学到高中的十二年一贯制教育体系，让成长持续不断。一贯制的课程体系，从一而终才能让教育理想不“落空”。跨学段的朋辈教育，让前行者成为后来者最好的领路人。统一的校园文化，让教育潜移默化。
            </span>
          </div>
          <div className='content'>
            <span className='summary colon'>寄宿制</span>
            <span className='detail'>
              德普外国语学校以寄宿制为主。寄宿远不是换个地方睡觉，它包含着同龄人的团队合作与冲突。德普希望孩子们能在“冲突与和解”中学会协作，学习正确面对矛盾，学习与他人和谐相处。
            </span>
          </div>
          <div className='content'>
            <span className='summary colon'>
              纯小班/双班主任制/一对一家长会
            </span>
            <span className='detail'>
              德普外国语学校坚信，每一位学生都是艺术品，应该被认真对待。在这里，所有班级都是30人以内的小班，每个班配备2名班主任，每个学生都有更多展示自我的机会；学校实行一对一家长会，学科老师与班主任一对一与学生和家长沟通。在这里，拒绝让学生成为他人的陪读。
            </span>
          </div>
          <div className='content'>
            <span className='summary colon'>让教育回归本质</span>
            <span className='detail'>
              拒绝功利主义，尊重学生成长规律，让教育回归到“有利于学生成长为完整独立的个体”的初衷。
            </span>
          </div>
          <div className='content'>
            <span className='summary colon'>学生不是复制品</span>
            <span className='detail'>
              德普教育理念之一，德普坚信每一个孩子都是艺术品，具有无限可能。
            </span>
          </div>
          <div className='content'>
            <span className='summary colon'>点燃</span>
            <span className='detail'>
              要教会一群人造船，只需要点燃他们内心对大海的渴望。德普希望点燃孩子们内容对知识的渴望，对世界的好奇，而不是单向地传授。
            </span>
          </div>
          <div className='content'>
            <div className='summary'>周末幸福派</div>
            <div className='detail'>
              以“做饭、家务、运动三大件”为主的周末幸福派，从2019年最初在德普双语初中推出，现在已推广到全校。人间烟火气，最是慰人心。从小处着手，引导孩子们接纳真实的生活。教会他们从日常生活之中，学会获得幸福的能力。
            </div>
          </div>
          <div className='content'>
            <div className='summary'>断舍离</div>
            <div className='detail'>
              从2022年3月开始，“断舍离”就在德普小初高中施行，老师和同学们一起“断舍离”，将无序的空间变得有序。这不仅是一个让物品变得条理的过程，也是同学们梳理思维的过程。德普希望，通过整理所处的环境，同学们能获得区分混乱与秩序的能力，把自己的生活打理得干净整洁、井井有条。
            </div>
          </div>
          <div className='content'>
            <div className='summary'>少的力量</div>

            <div className='detail'>
              德普认为，“好的教育，是灵魂的建设，不是物质的堆积”，在这个“多”的世界，让我们重新认识“少”的力量，对物欲、空间、情绪、压力及时做减法，Less
              For More，更积极乐观、更欢喜地过好这一生。
            </div>
          </div>
          <div className='content'>
            <div className='summary'>大卫俱乐部</div>

            <div className='detail'>
              大卫俱乐部以“有干劲，有抱负，有活力，有灵气，有梦想”为信念，根据每个孩子的不同，量身打造个人方案，匹配最合适的导师，因材施教。鼓励学生一路向前，走向自己的星辰大海。
            </div>
          </div>
          <div className='content'>
            <div className='summary'>学校课程</div>

            <div className='detail'>
              在国家课程框架下，融合全球哲科思维课程，辅以创新国学课程，采用过程目标双导向的评估体系，培养懂中国懂世界的创变者。
            </div>

            <div>
              培养、引导学生用哲科思维，不断思考事物背后的本质，用逻辑去拷问。追究和拷问，带来更多的理性和探索；研习国学经典，怀仁爱之心，明变通之道，格物致知，知行合一。以结果为导向，也重视过程的力量。
            </div>

            <ul>
              <li>小学糖果课程-ACNDIE</li>
              <li>学科英语课程-Cambridge English for Subjects</li>
              <li>国学浸润课程-Absorbing Sinology</li>
              <li>国家义务课程-National Compulsory Curriculum</li>
              <li>生涯行知课程-Discovery and Inquiry</li>
              <li>素质拓展课程-Extra-Curriculum</li>
              <br />
              <li>小班初中杠杆课程-LEVER</li>
              <li>语言课程—Language Study</li>
              <li>国家义务课程-Essence of Chinese Curriculum</li>
              <li>艺术感知课程-Vision of Arts</li>
              <li>学术延展课程-Extensive Academic Course</li>
              <li>体育健康探究-Research of Sports & Health</li>
              <br />

              <li>双语初中棱镜课程-LNSES</li>
              <li>生涯教育-Life Education</li>
              <li>学科英语课程-English for Cambridge Curriculum</li>
              <li>国家义务课程-National Compulsory Curriculum</li>
              <li>语言课程-Study of Language</li>
              <li>体艺素养课程-Extra-Curriculum Activity</li>
              <li>国学浸润课程-Sinology Study</li>
              <br />

              <li>国际高中指针课程-COMPAS</li>
              <li>中国高中课程-Chinese Senior Curriculum</li>
              <li>国际精选课程-Oriented International Curriculum</li>
              <li>语言课程-Multiple Language Learning</li>
              <li>高阶生涯教育-Advanced Life Education</li>
              <li>中外文史哲探究-Sino-Western Culture & Philosophy</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='fruits'>
        <div className='title'>
          <div className='line' />
          <div className='text'>办学成果</div>
          <div className='line' />
        </div>
        <div className='each'>
          <div className='sub-title'>学生成绩</div>
          <div className='content'>
            2022年，在希望之星英语风采大赛中，获1个特等奖、13个一等奖，二等奖三等奖若干；在袋鼠数学竞赛中获3个满分成就奖，6个超级金奖，22个金奖，以及银奖、铜奖、技能奖若干；在全国中学生英语能力测评中获得1个全国一等奖、3个全国二等级，6个全国三等奖，市级一二三等奖若干；在SPBCN国际英文拼词大会重庆赛区决赛中小学组获2个一等奖，初中组中获得8个一等奖；在2022BPHO英国物理奥林匹克竞赛中级中获得3个全球金奖、17个全球银奖，全球铜奖若干；在美国数学竞赛AMC8中获得1个全球卓越奖，4个全球优秀奖等。
            <br /> <br />
            2021年，学校学生总计获得348个国家级奖项、100个市级奖项、14个区级奖项，包括学术竞赛、体育竞技、文化艺术等方面。在国际英文拼词大会中，获总决赛季军、小学初级组高级组特等奖；在NEC全美经济学挑战赛中，获全国个人总分奖一等奖、全国个人单科优胜奖；在BPA商业全能挑战赛区域站中，获全国个人总分奖铜奖、银奖；在AMC8美国数学竞赛中，获全球优秀奖4人、中国赛区二等奖5人、三等奖9人；在澳大利亚数学竞赛中，一等奖1人、二等奖16人、三等奖若干；在全国中学生英语能力测评中，包揽一、二、三等奖；在第五届全国青少年无人机大赛地区赛中，获一、二、三等奖；在第四届“活”的教育全国美术教学成果展中，获一、二、三等奖；在重庆市青少年壁球积分赛中，获一、二、三名等。
            <br /> <br />
            2020年，学校学生在澳大利亚科学竞赛中，获一等奖3人、二等奖6人、三等奖6人；在澳大利亚袋鼠数学竞赛中，2人获满分成就奖，超级金奖5名，金奖、银奖、铜奖若干；在英国物理挑战赛中，金奖2人，银奖3人，铜奖4人；在美国生物奥林匹克竞赛中，银奖1人，铜奖1人；在全国中学生英语能力测评竞赛（(NEPTS）中，获全国一等奖1人、二等奖3人、三等奖6人，市级一等奖4人、二等奖5人、三等奖11人。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>教师成绩</div>
          <div className='content'>
            2022年，学校老师获全国优质教育教学成果展一等奖；重庆市新时代好班主任五项全能二等奖；重庆市初中历史现场优质课大赛一等奖；重庆市第六届书法优质课现场竞赛一等奖；重庆市中学生物理教师教学技能特等奖；重庆市中学生物理创新实验教具二等奖；重庆市德育精品课程二等奖；重庆市第一届中学化学双语优质课比赛一等奖；指导学生参加第六届全国青少年无人机大赛暨重庆市选拔赛荣获指导教师奖一、三等奖。
            <br /> <br />
            2021年，获名师优课2021国家级一、二、三等奖；第十六届全国语文教师“四项全能”竞赛一等奖、二等奖；第四届重庆市数学文化节比赛优秀指导教师一等奖；重庆市中学物理创新实验教具展评二等奖；第七届小学科学（教科版）优秀课评比赛“二等奖”；2021年全国中学生英语能力测评全国指导教师奖一、二、三等奖；FIT国际教师风采大赛二等奖；剑桥国际中心课程整合创新大赛优秀奖等。
            <br /> <br />
            2020年，在全国中学生英语能力测评大赛中，获得国家级指导教师一、二、三等奖若干，市级指导教师一、二、三等奖若干；在重庆市初中英语优质课大赛中，获得现场赛课一等奖、教研组奖一等奖、指导教师奖一等奖；在重庆市第五届中小学法制教育优质课竞赛中，获得现场赛课二等奖、指导奖二等奖、教研组奖二等奖；在重庆市小学数学教研论文项目中，获得二等奖；在重庆市青少年科技创新大赛科技中，获辅导员优秀论文三等奖；在重庆市第九届中小学班主任基本功大赛中，获“教师成长专业叙事”三等奖；在第五届全国青少年无人机大赛重庆赛区中，获指导教师二、三等奖等。
            <br /> <br />
            此外，多名老师还是澳大利亚数学AMC指导教师、英国数学测评（高级)/SMC指导教师、美国物理奥林匹克/BPHO指导教师、牛津大学编程思维挑战
            /
            OUCC指导教师、澳大利亚中级数学奥林匹克指导教师、澳大利亚科学奥林匹克指导教师等。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>学校荣誉</div>
          <div className='content'>
            2019中国新锐国际学校
            <br />
            2019京领中国国际学校竞争力排名英国方向第55名
            <br />
            2020胡润百学·中国百强国际学校
            <br />
            2021胡润百学·中国国际学校百强第43名
            <br />
            2022胡润百学·中国国际学校百强第41名
          </div>
        </div>
      </div>

      <div className='culture'>
        <div className='title'>
          <div className='line' />
          <div className='text'>文化传统</div>
          <div className='line' />
        </div>
        <div className='content'>
          学校以“修之于天下，其德乃普”为校训，立志于将学生培养成“懂中国，懂世界的创变者”。学校坚持学生不是复制品，希望让每个孩子发现自己的优势，留住孩子与生俱来的无限可能。实现让教育回归本质，让学生人尽其才，让家长告别焦虑，把“亲近”还给家庭。
        </div>
        <div className='section'>学校标识</div>
        <div className='each'>
          <div className='sub-title'>校训</div>
          <div className='content'>修之于天下，其德乃普</div>
        </div>
        <div className='each'>
          <div className='sub-title'>育人目标</div>
          <div className='content'>
            培养懂中国，懂世界的创变者
            <br />
            懂中国：仁爱之心、变通之道、 顺势而为、知行合一
            <br />
            懂世界：哲科素养、批判思维、契约精神、全球视野
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>使命</div>
          <div className='content'>
            面向快速变化、充满不确定性的未来，深刻理解家长焦虑，回归教育本质，创新教育和管理，打造真正中西融合的12年一贯制课程体系，培养健康快乐、懂中国懂世界的创变者。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>愿景</div>
          <div className='content'>
            成为3.0时代国际化学校的引领者，标准制定者。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>校歌——《不问西东》</div>
          <div className='content'>
            歌词：星辰大海是未来的懵懂，秋收冬藏是生命的悸动。不冷漠、不从众、明是非、知变通，一粥一饭是生活的隆重。莎士比亚与红楼一梦，求知的风，理想的梦，古今中外，不问西东。只为求知而来，只为乘兴而去。修于天下，不问，不问西东。
          </div>
        </div>
        <div className='section'>社团/活动</div>
        <div className='each'>
          <div className='sub-title'>菜鸟周</div>
          <div className='content'>
            德普新生第一课在德普，每个新生都是“菜鸟”。每年秋季开学的第一周，“菜鸟们”都需要上“菜鸟课程”。课程会延续一周（有的学部会持续更久），于是就有了“菜鸟周”。
            <br />
            “菜鸟周”学什么？学习时间管理、仪容仪表的标准、清洁收纳、电子产品管理……这些都是它的内容。德普愿意“浪费”开学第一周的时光，给同学们传授经验，让“菜鸟们”拥有面对人生新阶段的勇气和力量。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>五千盐</div>
          <div className='content'>
            德普创办的对话栏目，通过与各行业的精英对话，为德普学子打开不同的窗。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>德普吉尼斯</div>
          <div className='content'>
            德普传统活动之一，让每一个孩子有机会展露自己的与众不同。
            <br />
            曾经，有人在这里以56分35秒的成绩，打破了单脚站立的记录，硬是把一张gif站成jpg；有人以30分钟25秒的成绩，打破了转圈记录的最高纪录，还有憋气、投壶、背圆周率、吃面……在这里，你就是吉尼斯。Guiness（吉尼斯）？Genius（天才）！
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>凉虾音乐节</div>
          <div className='content'>
            属于六月毕业季的德普经典活动，凉虾是重庆夏日里人人喜爱的小吃，也是德普送给学生们的一场最酣畅淋漓的夏日礼物。夏夜、微风、凉虾、裙摆、吉他、尖叫……都属于这个盛大的夜晚。同学们青春的热情和期待，都将在这里释放和实现。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>iBusiness德普商业挑战赛</div>
          <div className='content'>
            德普五星级赛事，由德普经商组举办的商业挑战赛，每年都吸引了数百名不同学部不同年级的学生参赛。全英文讲解商业策划案，竞争激烈。华为、花旗银行、民生银行、赛力斯、江小白……同学们走进企业，感受真实的商业环境，跟企业一起，解决实际问题。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>CCA选修课</div>
          <div className='content'>
            CCA，英文全称Co-Curriculum
            Activity，全校共开设140余门，涉及人文、社科、艺术、体育等多学科。川剧、戏剧、攀岩、击剑、编程、陶艺、篆刻、木工……帮助学生构建学科知识之外，诸如实践、审美、协作、领导力、批判、表达等方面的综合能力。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>冬日晚会</div>
          <div className='content'>
            德普的年终盛宴。每年的12月末，德普的冬日晚会就会如期到来。每个参与的同学都收获着属于自己的独特体验。或新奇、激动、生涩，或热烈、成长、触动。它是一场“育人”的实践和落地，是德普在课堂“教”知识之外，践行“育”的力量。台前幕后的联袂付出，无数个全力以赴的日子，让更多的种子生根发芽。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>每日夸夸</div>
          <div className='content'>
            德普学生有一个计划反思本，每日夸夸是其中的一个特别栏目。生活中从不缺少美，而是缺少发现美的眼睛。每日夸夸，让我们用欣赏的眼光去看待周围的人和事，发现世界的美好，常怀感恩之心。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>话剧演出</div>
          <div className='content'>
            德普年度大戏。每年德普的戏剧社团都会公演一出全本话剧，时长超过3小时。从《恋爱的犀牛》到《夏洛特烦恼》，从《德龄与慈禧》到《四世同堂》，一群十几岁的孩子不断创造奇迹，在另一个剧本中体验人生。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>PTA德普家校联合会</div>
          <div className='content'>
            全称为Parents & Teachers
            Association，德普家校联合会。旨在联合全体家长，形成教育合力，以促进学生全面发展为目的，为培养下一代成为“懂中国，懂世界创变者”共同努力。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>校长有约</div>
          <div className='content'>
            每一学期，德普都坚持做“校长有约”，由校长跟同学们面对面交流。这不是一个只听校长发言的地方，而是一个“不断发问、交流，思考碰撞”的地方。通过这种方式，我们想了解在学习之外，同学们还在关注什么，在思考什么，在疑惑什么。
          </div>
        </div>
        <div className='each'>
          <div className='sub-title'>校长特别奖</div>
          <div className='content'>
            德普学子最高荣誉“校长特别奖”是德普学子的最高荣誉，旨在从全校范围内，寻找具有德普特质的学子。在全校范围内，经过自愿申请、层层选拔，最终诞生“校长特别奖”获得者。
          </div>
        </div>
      </div>

      <div className='leaders'>
        <div className='title'>
          <div className='line' />
          <div className='text'>校领导</div>
          <div className='line' />
        </div>

        <div className='each'>校长：许凌可</div>
        <div className='each'>小学部校长：孙继先</div>
        <div className='each'>小班初中部校长：夏丽琼</div>
        <div className='each'>双语初中部校长：孟姜</div>
        <div className='each'>高中部校长：张韧</div>
        <div className='blank'></div>
        <div className='each'>党总支书记：夏丽琼</div>
      </div>

      <div className='teachers'>
        <div className='title'>
          <div className='line' />
          <div className='text'>全球校友会</div>
          <div className='line' />
        </div>
        <div className='each'>
          <div className='content'>
            随着毕业生人数的增多，为了更好地服务和连接孩子们，德普成立了全校校友会，尽一切努力，给孩子们提供必要的帮助，德普永远是德普学子的归属地。
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
