import React, { useState, useEffect, useRef } from 'react';

import './index.less';

const { default: PreventScroll } = require('prevent-scroll');

export default ({ text = '', loading = true }) => {
  const [isLoading, setIsLoading] = useState(loading);
  const timerRef = useRef<number>();
  useEffect(() => {
    clearTimeout(timerRef.current);
    if (!loading) {
      timerRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(loading);
    }
  }, [loading]);
  useEffect(() => {
    if (isLoading) {
      PreventScroll.on();
    } else {
      PreventScroll.off();
    }
  }, [isLoading]);
  return isLoading ? (
    <div className={'Component-loading'}>
      <div className='Loading-wrap'>
        <img src={require('./loading.svg')} className='Wrap-img' alt={''} />
        <span className='Loading-text'>{text}</span>
      </div>
    </div>
  ) : null;
};
