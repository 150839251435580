import React from 'react';
export default [];
export const vs = [
  { left: '以中考和高考为目标', right: '以就读全球名校为目标' },
  {
    left: (
      <>
        <div>使用统编教材和国内</div>
        <div>教学大纲</div>
      </>
    ),
    right: (
      <>
        国家义务教育课程体系
        <div> +学科英语</div>
      </>
    )
  },
  {
    left: '以应试教育为主',
    right: (
      <>
        <div>以多元化发展和全人</div>
        <div>教育为主</div>
      </>
    )
  },
  {
    left: (
      <>
        <div>以考试成绩为核心</div>
        <div>评估标准</div>
      </>
    ),
    right: (
      <>
        <div>过程与结果相结合</div>
        <div>多维度评估体系</div>
      </>
    )
  },
  {
    left: (
      <>
        <div>培养学生背诵</div>
        <div>和计算能力</div>
      </>
    ),
    right: (
      <>
        <div>培养学生创新</div>
        <div>和解决问题的能力</div>
      </>
    )
  },
  {
    left: '适合大多数普通家庭',
    right: (
      <>
        <div>适合具有出国留学</div>
        <div>规划的家庭</div>
      </>
    )
  }
];

export const Course = [
  {
    left: '生涯教育课程',
    right: 'Life Education'
  },
  {
    left: '学科英语课程',
    right: 'English for Core Courses'
  },
  {
    left: '国家义务课程',
    right: 'National Compulsory Curriculum'
  },
  {
    left: '语言课程',
    right: 'Study of Language'
  },
  {
    left: '体艺素养课程',
    right: 'Extra-Curriculum Activity',
    content:
      '高尔夫、橄榄球、马术、壁球、击剑、剑道、游泳、攀岩、咏春、足球、网球、围棋、羽毛球、踢踏舞、合唱、街舞、农场、英语戏剧、川剧、表演、电影工作坊、非洲鼓、美术、第二外语、涂鸦',
    leftVW: '82vw'
  },
  {
    left: '国学浸润课程',
    right: 'Sinology Study'
  }
];
