import React from 'react';
import EM from '@utils/EM';
const dataFilter = (str: string) => {
  const re: JSX.Element[] = [];
  const lines = str.split('\n');
  let tempLines = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.trim() === '') {
      const gapIndex = tempLines.findIndex(x => x.includes('任职资格'));
      re.push(
        <>
          <div className='title'>{tempLines[0].replace(/\d+、/, '')}</div>
          <div className='subTitle'>岗位职责</div>
          {tempLines.slice(2, gapIndex).map(line => {
            return (
              <div className='t' key={line}>
                {line}
              </div>
            );
          })}

          <div className='subTitle'>任职资格</div>
          {tempLines.slice(gapIndex + 1, tempLines.length).map(line => {
            return (
              <div className='t' key={line}>
                {line}
              </div>
            );
          })}
          <div
            className='btn'
            onClick={() => {
              EM.emit('resume');
            }}>
            立即应聘
          </div>
          <div className='gap' />
        </>
      );
      tempLines = [];
    } else {
      tempLines.push(line);
    }
  }
  return re;
};
// 社会招聘
const shzpStr = `1、小学语文骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格
1.全日制大学本科及以上，部属师范院校或“985”、“211”重点大学的研究生优先；
2.汉语言文学相关专业，具备扎实深厚的学科专业背景和优秀的专业素养；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

2、小学数学骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.全日制大学本科及以上，部属师范院校或“985”、“211”重点大学的研究生优先；
2.数学及应用数学相关专业，具备扎实深厚的学科专业背景和优秀的专业素养；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

3、小学双语数学教师
岗位职责：
运用英语进行A-Level课程体系的数学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.学历：数学及应用数学相关专业，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先，有海外背景优先；
2.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业；
3.口语纯正，能熟练运用英语开展教学，已过专业八级者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；

4、小学英语骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.全日制大学本科及以上，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先，有海外背景优先；
2.英语及相关专业，且需获得专业八级证书；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

5、双语科学教师
岗位职责：
运用英语进行A-Level课程体系的科学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.学历：普通全日制大学本科及以上，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先，有海外背景优先；
2.科学相关专业；
2.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业；
3.口语纯正，能熟练运用英语开展教学，已过专业八级或英语六级者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；

6、中学语文骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格
1.全日制大学本科及以上，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先；
2.汉语言文学相关专业，具备扎实深厚的学科专业背景和优秀的专业素养；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

7、中学数学骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.全日制大学本科及以上，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先；
2.数学及应用数学相关专业，具备扎实深厚的学科专业背景和优秀的专业素养；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

8、中学英语骨干教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.全日制大学本科及以上，全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先；有海外背景优先；
2.英语及相关专业，且需获得专业八级证书；
3.具有较强的教学科研能力与指导青年教师的能力；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

9、国际高中语文教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.汉语言文学及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且英语口语纯正者优先，已过专业八级者优先；
5.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

10、国际高中英语教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.英语及相关专业，需过专业八级且口语纯正；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异优先；
5.热爱教育事业、身体健康，形象气质佳，综合素质强、具有较强的表达沟通能力、团队精神。

11、双语数学教师
岗位职责：
运用英语进行A-Level课程体系的数学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.数学学科及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.热爱教育事业、身体健康，形象气质佳，综合素质强、具有较强的表达沟通能力、团队精神。

12、双语生物教师
岗位职责：
运用英语进行A-Level课程体系的生物学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.生物学科及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

13、双语经济教师
岗位职责：
运用英语进行A-Level课程体系的经济学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.经济、金融、会计及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

14、双语化学教师
岗位职责：
运用英语进行A-Level课程体系的化学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.化学及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

15、双语地理教师
岗位职责：
运用英语进行A-Level课程体系的地理学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.地理及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

16、体育教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.普通全日制大学本科及以上；
2.所教学科相关专业（羽毛球、网球、篮球、田径方向）；
3.具有较强的教学科研能力与指导青年教师的能力；
4.具备扎实深厚的学科专业背景和优秀的专业素养；
5.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

17、图书管理员
岗位职责：
1、具有完善的出版物进出登记台账，做好读者的图书出借和阅读工作，不断改进工作方法，简化借阅手续，提高图书流通率。
2、有计划、有原则地选购图书，订阅期刊，及时添购新书籍，书柜，保证图书经费的合理使用。
3、按照图书编目的要求，对藏书分类编目，新买的书刊及时分编上架，投入流通，不无故积压。
4、经常宣传新书内容，开展读书活动，做好书刊鉴别工作，严禁内容不健康的书刊出借和阅览。
5、爱护图书财产，定期查库账物要清，经常保持图书馆、阅鉴室和资料整洁通风，做好防虫、防潮、防灾、防盗等安全保护工作。
6、定期做好报纸、杂志的装订和保管工作，及时修补损坏的书籍杂志。
7、对读者做好爱护图书和遵守阅览室制度的宣传教育工作。
8、认真做好防盗、防火、防潮、防虫工作，搞好书库、借书室、阅览室的清洁卫生工作。
9、完成学校委派的其他工作。
任职资格：
1.普通全日制大学本科及以上；
2.有2年以上图书馆管理相关经验；
3.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱本职工作。
`;
export const shzp = dataFilter(shzpStr);
const xyzpStr = `1、小学语文老师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格
1.全日制大学本科及以上，部属师范院校或“985”、“211”重点大学的研究生优先；
2.一等奖学金获得者优先；
3.汉语言文学相关专业，具备扎实深厚的学科专业背景和优秀的专业素养；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

2、小学英语老师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.全日制本科毕业及以上学历，部属师范院校或“985”、“211”重点大学的研究生优先，有海外背景优先；
2.英语及相关专业，且需获得专业八级证书；
3.具备扎实深厚的学科专业背景和优秀的专业素养；
4.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

3、国际高中语文教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.汉语言文学及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且英语口语纯正者优先，已过专业八级者优先；
5.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

4、国际高中英语教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.英语及相关专业，需过专业八级且口语纯正；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异优先；
5.热爱教育事业、身体健康，形象气质佳，综合素质强、具有较强的表达沟通能力、团队精神。

5、双语数学教师
岗位职责：
运用英语进行A-Level课程体系的数学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.数学学科及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.热爱教育事业、身体健康，形象气质佳，综合素质强、具有较强的表达沟通能力、团队精神。

6、双语生物教师
岗位职责：
运用英语进行A-Level课程体系的生物学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.生物学科及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

7、双语经济教师
岗位职责：
运用英语进行A-Level课程体系的经济学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.经济、金融、会计及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

8、双语化学教师
岗位职责：
运用英语进行A-Level课程体系的化学学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.化学及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

9、双语地理教师
岗位职责：
运用英语进行A-Level课程体系的地理学科教学
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.部属师范院校或“985”、“211”重点大学的研究生优先；归国留学生毕业学校综合排名或专业排名居世界前30位者优先；
2.地理及相关专业；
3.具备扎实深厚的学科专业背景和优秀的专业素养，应届生获得一等奖学金者优先，成熟教师业绩突出者优先；
4.TOEFL、IELTS成绩优异且口语流利纯正者优先；
5.有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；
6.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。

10、体育教师
岗位职责：
1.严格遵循教学计划和教学大纲规定，合理组织教学内容，制定课程进度计划，负责课程所需的多媒体课件。
2.负责课程的主讲（指导）、辅导、答疑、批改作业、负责课堂纪律和考勤工作，保证正常的教学秩序。
3.负责主持该课程的考核，并及时评定学生成绩，做好成绩分析工作。
4.以及安排的其他教学工作等。
任职资格：
1.普通全日制大学本科及以上；
2.所教学科相关专业（羽毛球、网球、篮球、田径方向）；
3.具有较强的教学科研能力与指导青年教师的能力；
4.具备扎实深厚的学科专业背景和优秀的专业素养；
5.身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业。
`;
export const xyzp = dataFilter(xyzpStr);

export const sxzp = [
  <>
    <div className='title'>历史老师</div>
    <div className='subTitle' />
    <div className='t'>在校大学生（大三大四或在读研究生）；</div>
    <div className='t'>协助在职教师辅导学生（课程和活动）；</div>
    <div className='t'>全职在校工作；</div>
    <div className='t'>专业知识扎实，综合素质高；</div>
    <div className='t'>
      身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业；
    </div>
    <div className='t'>口语纯正，能熟练运用英语开展教学优先。</div>
    <div className='subTitle' />
    <div className='subTitle' />
    <div className='title'>数学教师</div>
    <div className='subTitle' />
    <div className='t'>在校大学生（大三大四或在读研究生）；</div>
    <div className='t'>协助在职教师辅导学生（课程和活动）；</div>
    <div className='t'>全职在校工作；</div>
    <div className='t'>专业知识扎实，综合素质高；</div>
    <div className='t'>
      身体健康、形象气质佳、具有较强团队精神与合作意识，热爱教师职业；
    </div>
    <div className='t'>口语纯正，能熟练运用英语开展教学优先。</div>
    <div
      className='btn'
      onClick={() => {
        EM.emit('resume');
      }}>
      立即应聘
    </div>
  </>
];
