export default `
01陈诗涛 Healthcliff
国际高中部德育主任
西南政法大学翻译硕士
重庆市一级教师
CAIE教师培训证书持有者
中国教育学会会员（所属中小学德育研究分会）
重庆教育学会会员（所属班主任专业委员会）
descEn
Director of Moral education of International  Senior Department
Master of Translation and Interpreting of SWUPL
First-grade teacher certificate holder
CIE teacher training certificate holder
Member of The Chinese Society of Education
Member of Chongqing Education Institute
--
02雷小寒 Tracy
双语数学老师
英国曼彻斯特大学，运筹学与风险分析硕士；
银行与金融学学士；
CIE/Edxcel/AP Statistics教师培训证书持有者
资深数学竞赛杰出教练
EPQ学术导师
剑桥教师发展中心（PDQ）导师
SAS证书、CFA（特许金融分析师）Level 1
所教学生获牛津大学录取
descEn
Bilingual Math Teacher of International  Senior Department
Master of Operational Research and Risk Analysis from The University of Manchester
Bachelor of Banking and Finance 
CIE & Edexcel& AP teacher training certificate holder
Mathematics competion outstanding coach
EPQ Academic Mentor
Cambridge Teacher PDQ mentor
SAS certificate and Level 1 CFA
Students admitted to Oxford University
--
03张立妮 Nikki
国际高中英语教师
澳大利亚麦考瑞大学教育学硕士、四川大学文学双学士
重庆市一级教师
剑桥大学教师职业发展资格证书（PDQ）持有者、项目导师
全国中学生英语竞赛优秀指导教师
国际高中牛剑骨干教师
中国教育学会、重庆市教育学会会员
14年国际教育教学及管理经验
所教学生获牛津大学录取
descEn
English Teacher of International Senior Department
Master of Education and Work, Macquarie University, Australia  
Bachelor’s degree with a double major in Arts, Sichuan University
First-grade teacher certificate holder
Cambridge University PDQ certificate holder, program mentor
National Excellent Supervisor in National English Proficiency Test
Senior department Oxbridge program teacher
Member of The Chinese Society of Education
Member of Chongqing Education Institute
14 years' teaching and management experience in international education area
Students admitted to Oxford University
--
04邓澄 Tracy
国际部商科及经济学双语教师
澳大利亚麦考瑞大学会计硕士
四川外语学院英语翻译学士
经商组教研组长
重庆一级教师
德普商业挑战赛总负责人
原普华永道中天会计师事务所审计师；
EPQ学术导师
全美经济挑战赛，全美未来商业领袖挑战赛优秀指导教师
所带团队荣获NEC全美经济挑战赛中国总决赛金奖、城市之星（该城市第一名）
descEn
Bilingual Teacher of Business Studies and Economics of International Senior Department
Master of Accounting of Macquarie University
Bachelor of English Translation from Sichuan International Studies University
Head of Business and Economics Team
Chongqing First-grade teacher
Director of DEPU iBusiness Challenge
Former Auditor of Pricewaterhouse Coopers
EPQ Academic Mentor
Excellent Instructor of NEC and FBLA
Students won Gold Medal in National Economics Challenge China Final and City Award (Top1 in city)
--
05侯丹 Sophie
国际高中升学指导
从事留学行业16年
英语专业八级
国际职业顾问协会生涯规划师
成功辅导600余名学生，100%的录取成功率，录取学校包括：
牛津大学、加州伯克利、帝国理工、伦敦政经、伦敦大学学院、新加坡南洋理工、多伦多大学、香港大学、悉尼大学、早稻田大学、伦敦艺术大学
descEn
College Counselor of International Senior Department
16 years of experience in the studying abroad industry TEM-8
IACA-Certified Career Advisor
More than 600 successful college application cases. Universities and colleges involved including:
University of Oxford, University of California, Berkeley, Imperial College London, The London School of Economics and Political Science, University College London, Nanyang Technological University, University of Toronto, University of HongKong, The University of Sydney, Waseda University, University of the Arts London
--
06Walayat Ali
国际高中物理老师
美国密歇根州立大学物理学硕士
11年国际课程教育经验
EPQ学术导师
所教学生获牛津大学录取
descEn
IGCSE , A Level and AP  Physics Teacher
Master of Science ( Physics and Science education) from MSU -USA 
11 years of teaching CIE IGCSE and A level Physics , Edexcel IGCSE and  A level Physics and AP Physics.
EPQ Academic Mentor
Students admitted to Oxford University
--
07David Hylands
国际高中英语教师
16年英语教学经验
开放大学（英国），教育研究学荣誉学士
托莱多大学，工商管理学士
剑桥教师职业发展项目PDQ导师
21世纪牛津教师学院技能证书
descEn
English teacher
16 years of teaching English
Open University, BA (Honours) Education Studies (Primary)
University of Toledo, Bachelor of business administration
Cambridge Teacher PDQ mentor
Oxford Teachers' Academy 21st Century Skills Certificate
--
08杨成静 Sandy
国际高中物理教师
西南大学理学硕士
CAIE教师培训证书
重庆市高级教师 
所教学生获牛津大学录取
BPHO物理奥赛，美国物理杯等国际物理奥赛金牌教练
所带团队获得BPHO-ROUND1超级金奖，BPHO-ROUND2全球银奖
descEn
Physics teacher of International Senior Department
Master of Science of Southwest University 
CIE teacher training certificate
Senior Teacher Certification possessing
Students admitted to Oxford University
Gold medalist's coach of BPHO, Physics bowl and other international physics Olympiad
Students won Super Gold of BPHO-ROUND1 and Global Silver of BPHO-ROUND2
--
09李菁 Elsa
国际高中双语社会学教师
英国约克大学应用语言学硕士
5年教学经验
四年班主任管理经验
剑桥教师职业发展项目PDQ导师
EPQ项目导师
人文社科大卫俱乐部导师
所教学生获牛津大学，帝国理工，南洋理工大学，伦敦大学学院，伦敦政治经济学院等大学录取。
descEn
Bilingual Sociology Teacher of International Senior Department
MA in Applied Linguistics, University of York, UK
5 years Teaching Experience
Four years of Homeroom Teacher experience
Cambridge Teacher PDQ mentor
EPQ Project Leader
DEPU Humanities and Social Sciences David Club Mentor
The students taught have been admitted to universities such as Oxford University, Imperial College London, Nanyang Technological University, University College London, and London Political and Economics Students.
--
10易睿 Ryan
国际高中化学双语老师
新加坡国立大学化学工程专业，辅修生命科学
曾就职于新加坡国立大学、新加坡格罗方德半导体股份有限公司
具有丰富的海外O level和A level教学经验
descEn
Bilingual Chemistry Teacher of International Senior Department
Bachelor of Chemical Engineering in National University of Singapore, second major in Life Sciences. 
Worked in National Univesity of Singapore and Global Foundries Singapore. 
Taught O level and A level maths, chemistry, physics, and biology in Singapore.
--
11张超 Kelly
国际高中双语艺术设计老师
伦敦艺术大学 影像专业硕士
厦门大学 城市规划专业学士
曾任影像作品集导师
参与展出《A Fragile》（伦敦）
descEn
Bilingual Art and Design Teacher of International Senior Department
Master of Photography from University of the Arts London
Bachelor of Urban Planning from Xiamen University
Portfolio tutor in Photography
Joined group exhibition A Fragile（The Art Academy，London）
--
12宋渝航 Veronica
国际高中双语社会学老师
伦敦政治经济学院，社会人类学硕士
北京外国语大学，英语语言文学学士
雅思8分，大三曾获国家留学基金委奖学金公派留学。
descEn
Bilingual Sociology Teacher of International Senior Department
MSc of Social Anthropology from London School of Economics and Political Sciences
BA of English from Beijing Foreign Studies University
IELTS 8; Selected as a candidate of study-abroad program by China Scholarship Council
--
13齐钰泠 Maggie
国际高中升学指导
哥伦比亚大学教育学硕士
对外经济贸易大学 英语翻译（文学学士），金融学（经济学学士）（双学位）
曾帮助学生录取到哥伦比亚大学、宾夕法尼亚大学、帝国理工大学、伦敦大学学院、香港大学等名校。
descEn
College Counsellor of International Senior Department
Master of Education, Columbia University
Bachelor of Arts & Bachelor of Finance (Double degree), University of International Business and Economics
Helped students to be admitted to many prestigious universities such as Columbia University, University of Pennsylvania, Imperial College London, University College London, University of Hong Kong, etc.
`;
