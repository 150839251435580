import React from 'react';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent>
      <img src={require('./bg.png')} alt='out of service' />
    </StyledComponent>
  );
};
