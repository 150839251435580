import React from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='学校简介'
        titleEn='DEPU Brief'
        content={<></>}
      />
      <div className='content'>
        <div className='section graduate'>
          <div className='title'>一所让教育回归本质的学校</div>
          <div className='content'>
            <div className='t'>是否还记得孩子刚出生时，你许下的心愿？</div>
            <div className='t'> “只要你健康、快乐！”</div>
            <div className='t'> 时光会改变你的初衷吗？ </div>
            <div className='t'>你会被潮流裹挟吗？ </div>
            <div className='t'>学习、补课、考证、过级</div>
            <div className='t'>小提琴、钢琴、国标、美术…… </div>
            <div className='t'>
              用这一切填满孩子睡觉以外的所有时间，这才对得起孩子，这才是良好的教育。
            </div>
            <div className='t'>孩子成了千人一面的标准品。</div>
            <div className='t'>你尽到了责任，却忘了问一句</div>
            <div className='t'> “孩子，你健康、快乐吗？”</div>
          </div>
        </div>
      </div>
      <div className='block'>
        <div>教育是否还有其它的可能？</div>
        <div>有没有这样一所学校：</div>
      </div>
      <div className='content '>
        <div className='section graduate'>
          <div className='content'>
            <div className='t'>学生被视作完整的人，而不是考试机器；</div>
            <div className='t'>
              每个学生得到同样的关爱，不会因为成绩的高低，让多数人沦为陪读。
            </div>
            <div className='t'>
              拒绝只用一把尺子衡量所有的学生，让每个孩子发现自 己的优势；
            </div>
            <div className='t'>
              学习知识的同时，学会与世界和睦相处，不畏竞争的同时，学会必要的妥协。
            </div>
            <div className='t'>在个性发展和规则圈宥之间，寻出一根平衡木； </div>
            <div className='t'>
              学会从点滴的生活中，体味幸福，而不是将全部的希望寄托于”远方”。
            </div>
            <div className='t'>
              让教育回归本质，留住孩子与生俱来的无限可能。
            </div>
            <div className='tt1'>德普，重新定义优秀</div>
            <div className='tt2'>DEPU，Redefining Excellence.</div>
          </div>
        </div>
        <div className='section graduate'>
          <div className='title'>
            一德普，3.0时代的国际化学校 引领者，懂中国，懂世界
          </div>
          <div className='content'>
            <div className='subTitle'>
              ——全校满员情况下，只有2000人的精品校区
            </div>
            <div className='t'>
              当一个学校拥挤得连呼吸都困难时，你怎能去要求孩子安心学习？那不是学校，那是工厂！
            </div>
            <div className='subTitle'>——纯小班、双班主任制、一对一家长会</div>
            <div className='t'>
              每一位学生都是独一无二的艺术品，都值得我们认真以待，所以我们所有班级都是25-30人的小班，每个班均配备2名班主任；所以每堂课，每个学生都有表现自我的机会；所以我们为每个学生定制私人学习计划；所以我们的一对一家长会，和老师交流时间可长达2小时；所以，德普每个学生都不会沦为他人的陪读。
            </div>
            <div className='subTitle'>——重庆剑桥国际中心与剑桥国际课程</div>
            <div className='t'>
              德普是剑桥大学国际考评部(Cambridge Assessment International
              Education)中国华南区域联盟成员，被剑桥国际授权为全球统一考试中心（编号：CN329），并命名为“重庆剑桥国际中心“。
            </div>
            <div className='t'>
              作为剑桥大学国际考评部授权学校，我们开设了剑桥国际IGCSE和A-Level课程，与全球18000多所剑桥国际学校共享教材和大纲等教学资源、师资培训体系。
            </div>
            <div className='t'>
              作为剑桥大学国际考评部官方考点，我们的考场设施全部达到剑桥国际的高标准要求，甚至严格到一个酒精灯外焰的温度。对德普的学生而言，全球统考的考场就是平日的教室。
            </div>
            <div className='subTitle'>——与课程完美配套的硬件设施</div>
            <div className='t'>
              如NBA规格的篮球馆、壁球馆、击剑馆、攀岩墙、迷你高尔夫室、24小时恒温游泳馆、专业形体室、舞蹈房、钢琴房等；
            </div>
            <div className='t'>
              如哈利波特式的图书馆、科幻世界的地理教室、一花一 世界的屋顶农场；
            </div>
            <div className='t'>如LOFT风格的咖啡厅、电影厅……</div>
            <div className='t'>
              在德普，所有硬件设施都是为了更好地服务于课程，而不是刻意追求外形的“高大上“，因为体育运动是我们的必修课程，阅读是我们教给孩子陪伴终身的养成，课后分组讨论是常态的作业模式，艺术鉴赏是让学生发现生活之美的基本素养……我们必须，也应该为每个学生拓展自己的天性提供设施保障。
            </div>
          </div>
        </div>
        <div className='section graduate'>
          <div className='title'>我们的理念</div>
          <div className='content'>
            <div className='subTitle'>育人目标：培养懂中国，懂世界的创变者</div>
            <div className='t'>怀仁爱之心，知变通之道, 顺势而为，知行合一 </div>
            <div className='t'>
              拥哲科素养，善用批判思维，守契约精神、培养全球视野
            </div>
            <div className='t'>
              持续创新，应对激烈竞争，勇于变革自我，迎接时代的发展
            </div>
            <div className='subTitle'>校训：修之于天下，其德乃普</div>
            <div className='t'>
              老子《道德经》云：“修之于身，其德乃真；修之于家，其德乃馀；修之于乡，其德乃长；修之于邦，其德乃丰；修之于天下，其德乃普。”
            </div>
            <div className='t'>君子何以知天下？</div>
            <div className='t'>
              以身观身，以家观家，以乡观乡，以邦观邦，修天下之德，以天下观天下。
            </div>
            <div className='subTitle'>
              愿景：成为新国际化学校的引领者，标准制定者。
            </div>
            <div className='subTitle'>
              使命：让教育回归本质，让学生人尽其才，让家长 告别焦虑。
            </div>
            <div className='subTitle'>
              课程特色：在国家课程框架下，融合全球哲科思维课程，辅以创新国学课程，采用过程目标双导向的评估体系，培养懂中国懂世界的创变者。
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
