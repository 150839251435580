import React, { useCallback, useState } from 'react';
import StyledComponent from './index.style';
import useApi from '@configs/apis';
const Select = (props: {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  options: Array<{ label: string; value: string }>;
}) => {
  return (
    <div className='value select'>
      <input
        type='text'
        placeholder={props.placeholder || '请选择'}
        readOnly
        disabled
        value={props.options.find(x => x.value === props.value)!.label}
      />
      <img className='select-icon' src={require('./select.svg')} alt='select' />
      <select
        value={props.value}
        onChange={e => {
          props.onChange && props.onChange(e.target.value);
        }}>
        {props.options.map(item => {
          return (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default () => {
  const [formValues, setFormValues] = useState([
    {
      name: 'truename',
      title: '姓名',
      value: '',
      type: 'text',
      placeholder: '请输入您的姓名',
      maxLength: 20
    },
    {
      name: 'gender',
      title: '性别',
      value: '0',
      type: 'select',
      options: [
        { label: '男', value: '0' },
        { label: '女', value: '1' }
      ],
      maxLength: 20
    },
    {
      name: 'birthday',
      title: '出生年月',
      value: '',
      type: 'date',
      placeholder: '请选择你的出生年月',
      maxLength: 20
    },
    {
      name: 'mobilePhone',
      title: '手机号码',
      value: '',
      type: 'number',
      placeholder: '请输入您的手机号码',
      reg: /^1\d{10}$/,
      maxLength: 11
    },
    {
      name: 'email',
      title: '邮箱',
      value: '',
      type: 'email',
      placeholder: '请输入您的邮箱',
      reg: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      maxLength: 20
    },
    {
      name: 'city',
      title: '居住城市',
      value: '',
      type: 'text',
      placeholder: '请输入您的居住城市',
      maxLength: 20
    },
    {
      name: 'graduateFrom',
      title: '毕业学校',
      value: '',
      type: 'text',
      placeholder: '请输入您的毕业学校',
      maxLength: 20
    },
    {
      name: 'major',
      title: '专业',
      value: '',
      type: 'text',
      placeholder: '请输入您的专业',
      maxLength: 20
    },
    {
      name: 'experience',
      title: '工作经验',
      value: '不足1年',
      type: 'select',
      options: [
        { label: '不足1年', value: '不足1年' },
        { label: '1年至3年', value: '1年至3年' },
        { label: '4年至8年', value: '4年至8年' },
        { label: '9年至15年', value: '9年至15年' },
        { label: '15年以上', value: '15年以上' }
      ],
      maxLength: 20
    },
    {
      name: 'skill',
      title: '教学成果',
      value: '',
      type: 'textarea',
      placeholder: '请输入您的教学成果',
      maxLength: 500
    },
    {
      name: 'selfEvaluation',
      title: '工作经历',
      value: '',
      type: 'textarea',
      placeholder: '请简单描述一下自己的工作经历',
      maxLength: 500
    }
  ]);
  const handleSumbit = useCallback(() => {
    for (const item of formValues) {
      if (!item.value) {
        alert(item.title + ' 不能为空');
        return;
      }
      if (item.reg && !item.reg.test(item.value)) {
        alert(item.title + ' 格式不正确');
        return;
      }
    }
    const value = {
      truename: formValues.find(x => x.name === 'truename')!.value,
      gender: parseInt(formValues.find(x => x.name === 'gender')!.value),
      birthday: formValues.find(x => x.name === 'birthday')!.value,
      mobilePhone: formValues.find(x => x.name === 'mobilePhone')!.value,
      email: formValues.find(x => x.name === 'email')!.value,
      city: formValues.find(x => x.name === 'city')!.value,
      graduateFrom: formValues.find(x => x.name === 'graduateFrom')!.value,
      major: formValues.find(x => x.name === 'major')!.value,
      experience: formValues.find(x => x.name === 'experience')!.value,
      skill: formValues.find(x => x.name === 'skill')!.value,
      selfEvaluation: formValues.find(x => x.name === 'selfEvaluation')!.value
    };

    useApi.resume.request(value).then(() => {
      alert('您的信息已提交成功，我们会尽快联系您!');
    });
  }, [formValues]);
  return (
    <StyledComponent>
      <div className='title'>个人信息</div>
      <div className='form'>
        {formValues.map(item => {
          return (
            <div
              className={item.type === 'textarea' ? 'item textarea' : 'item'}
              key={item.name}>
              <div className='key'>{item.title}</div>
              {item.type === 'select' ? (
                <Select
                  value={item.value}
                  placeholder={item.placeholder}
                  options={item.options || []}
                  onChange={value => {
                    item.value = value;
                    setFormValues([...formValues]);
                  }}
                />
              ) : (
                undefined
              )}
              {item.type === 'text' ||
              item.type === 'date' ||
              item.type === 'number' ||
              item.type === 'email' ? (
                <div className='value'>
                  <input
                    type={item.type}
                    value={item.value}
                    placeholder={item.placeholder}
                    maxLength={item.maxLength}
                    onChange={e => {
                      if (e.target.value.length > item.maxLength) {
                        return;
                      }
                      item.value = e.target.value;
                      setFormValues([...formValues]);
                    }}
                  />
                </div>
              ) : (
                undefined
              )}
              {item.type === 'textarea' ? (
                <div className='value textarea'>
                  <textarea
                    cols={30}
                    rows={10}
                    value={item.value}
                    placeholder={item.placeholder}
                    maxLength={item.maxLength}
                    onChange={e => {
                      if (e.target.value.length > item.maxLength) {
                        return;
                      }
                      item.value = e.target.value;
                      setFormValues([...formValues]);
                    }}
                  />
                </div>
              ) : (
                undefined
              )}
            </div>
          );
        })}
      </div>

      <div className='btn' onClick={handleSumbit}>
        提交
      </div>
    </StyledComponent>
  );
};
