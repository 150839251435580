type keys = 'third_token' | 'openid' | 'items' | 'token' | 'appid';

interface Storage {
  get: {
    (key: keys): string | null;
  };
  set: {
    (key: keys, value: string): void;
  };
  del: {
    (key: keys): void;
  };
  clear: () => void;
}

const prefix = 'yinyuejie';

const storage: Storage = {
  get: key => localStorage.getItem(`${prefix}-${key}`),
  set: (key, value) => localStorage.setItem(`${prefix}-${key}`, value),
  del: key => localStorage.removeItem(`${prefix}-${key}`),
  clear: () => localStorage.clear()
};

export default storage;
