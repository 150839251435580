import styled from 'styled-components';
export default styled.div`
  padding-bottom: 100px;
  > .content {
    padding: 0 34px 34px 34px;
    > .section {
      padding-top: 40px;
      > .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        color: #004844;
        padding-top: 80px;
      }
      &.what {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
      &.why {
        > .content {
          padding-top: 40px;
          > .subTitle {
            padding-top: 40px;
            font-size: 28px;
            line-height: 50px;
            color: rgba(0, 0, 0, 1);
            padding-bottom: 10px;
          }
          > .t {
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
      &.course {
        > .content {
          > .t {
            padding-top: 40px;
            font-size: 28px;
            font-weight: 300;
            line-height: 50px;
            color: rgba(102, 102, 102, 1);
          }
          > .subTitle {
            padding-top: 50px;
            width: 224px;
            height: 43px;
            font-size: 32px;
            font-weight: 400;
            line-height: 50px;
            color: rgba(0, 72, 68, 1);
          }
          > .table {
            padding-top: 40px;
            > .row {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 18px 0;
              font-size: 28px;
              font-weight: 400;
              line-height: 50px;
              color: rgba(255, 255, 255, 1);
              > .col {
                flex: 1;
                padding-left: 71px;
                position: relative;

                &:before {
                  content: ' ';
                  height: 8px;
                  width: 8px;
                  background-color: #fff;
                  position: absolute;
                  left: 42px;
                  top: 50%;
                  transform: translate(0, -50%);
                  border-radius: 100%;
                }
              }
              &:nth-child(1) {
                background-color: #004844;
              }
              &:nth-child(2) {
                background-color: #f5f5f5;
                color: #000;
                > .col {
                  &:before {
                    background-color: #b4b4b4;
                  }
                }
              }
              &:nth-child(3) {
                background-color: #9fb8a7;
              }
              &:nth-child(4) {
                background-color: #f5f5f5;
                color: #000;
                > .col {
                  &:before {
                    background-color: #b4b4b4;
                  }
                }
              }
              &:nth-child(5) {
                background-color: #004844;
              }
            }
          }
        }
      }
    }
  }
`;
