import styled from 'styled-components';
export default styled.div`
  .swiper-slide {
    width: 750px;
    height: 422px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    padding: 0;
    margin: 0;
    > img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .swiper-pagination {
    bottom: 0.5vw;
    .swiper-pagination-bullet {
      height: 1.5vw;
      width: 1.5vw;
      margin: 0 1vw;
      opacity: 0.5;
      &.swiper-pagination-bullet-active {
        background-color: #08a29a;
        opacity: 1;
      }
    }
  }
`;
